<template>
  <div class="account">
    <div class="header">
      <div class="container">
        <div class="title">{{ $t("navbar.account") }}</div>
      </div>
    </div>

    <div class="content">
      <div v-if="logged_in" class="section-1">
        <router-link to="/profile">
          <div class="btn">
            <div class="left">
              <div class="icon profile"></div>
              <h4 class="caption">{{ $t("home.profile") }}</h4>
            </div>
            <div class="icon arrow"></div>
          </div>
        </router-link>
        <router-link to="/favorites">
          <div class="btn">
            <div class="left">
              <div class="icon favorite"></div>
              <h4 class="caption">{{ $t("account.my_favorites") }}</h4>
            </div>
            <div class="icon arrow"></div>
          </div>
        </router-link>
        <router-link to="/addresses">
          <div class="btn">
            <div class="left">
              <div class="icon address"></div>
              <h4 class="caption">{{ $t("account.my_address") }}</h4>
            </div>
            <div class="icon arrow"></div>
          </div>
        </router-link>
      </div>
      <div v-else class="login">
        <div class="warn">
          <div class="icon"></div>
          <div class="caption">Please log in to access your profile.</div>
        </div>
        <div class="actions">
          <button @click="openSignupModal($route.name)" class="fill">
            {{ $t("registration.create_account") }}
          </button>
          <button @click="openLoginModal($route.name)" class="outline">
            {{ $t("registration.sign_in") }}
          </button>
        </div>
      </div>
      <div class="section-2">
        <translate></translate>
      </div>
      <div class="section-3">
        <div @click="showModal('contact_us_modal')" class="btn">
          <div class="left">
            <div class="icon contact"></div>
            <h4 class="caption">{{ $t("settings.contact") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </div>
        <div @click="showModal('about_modal')" class="btn">
          <div class="left">
            <div class="icon about"></div>
            <h4 class="caption">{{ $t("settings.about") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </div>
        <div @click="showModal('privacy_modal')" class="btn">
          <div class="left">
            <div class="icon privacy"></div>
            <h4 class="caption">{{ $t("settings.privacy_policy") }}</h4>
          </div>
          <div class="icon arrow"></div>
        </div>
      </div>
    </div>

    <div id="blur_background" class="blur-bg">
      <privacy-modal></privacy-modal>
      <contact-us-modal></contact-us-modal>
      <about-us></about-us>
      <login-modal></login-modal>
      <signup-modal></signup-modal>
      <otp-verification-modal></otp-verification-modal>
      <forgot-password-modal></forgot-password-modal>
    </div>
  </div>
</template>
<script>
import Translate from "../components/common/Translate.vue";
import PrivacyModal from "../components/common/PrivacyModal.vue";
import AboutUs from "../components/common/AboutUs.vue";
import ContactUsModal from "../components/common/ContactUsModal.vue";
import LoginModal from "../components/customer/LoginModal.vue";
import SignupModal from "../components/customer/SignupModal.vue";
import OtpVerificationModal from "../components/customer/OtpVerificationModal.vue";
import ForgotPasswordModal from "../components/customer/ForgotPasswordModal.vue";

export default {
  components: {
    Translate,
    PrivacyModal,
    AboutUs,
    ContactUsModal,
    LoginModal,
    SignupModal,
    OtpVerificationModal,
    ForgotPasswordModal,
  },
  mounted() {
    this.getNewUpdate();

    if (localStorage.token) {
      this.logged_in = true;
    }
  },
  watch: {
    $route(to, from) {
      if (from.query.login && localStorage.token) {
        this.logged_in = true;
      }
    },
  },
  data() {
    return {
      logged_in: false,
    };
  },
};
</script>
<style scoped>
.content {
  padding-top: 70px;
}
.btn {
  cursor: pointer;
}

.section-1 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  border-bottom: 1px solid #f0edf2;
  padding-bottom: 16px;
}
.section-2 {
  border-bottom: 1px solid #f0edf2;
  padding: 16px 0;
}
.section-3 {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 16px 0;
}

.profile {
  background-image: url("../assets/customer/account/profile.svg");
}

.favorite {
  background-image: url("../assets/customer/account/favorite.svg");
}

.address {
  background-image: url("../assets/customer/account/address.svg");
}

.section-3 .left .icon {
  background-color: #ffeeeb;
}

.section-3 .arrow {
  background-image: url("../assets/common/right_orange.svg");
  width: 24px;
}

.contact {
  background-image: url("../assets/customer/account/contact.svg");
}
.about {
  background-image: url("../assets/customer/account/about.svg");
}
.privacy {
  background-image: url("../assets/customer/account/privacy.svg");
}

.login {
  background-color: #fff7f5;
  border-radius: 8px;
  padding: 12px;
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.warn {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 400;
  font-size: 14px;
  color: #ff6a4d;
}
.warn .icon {
  width: 20px;
  height: 20px;
  background-size: 20px;
  background-image: url("../assets/customer/menu/warn.svg");
}
.actions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.actions button {
  width: 48.5%;
  border-radius: 8px;
  height: 44px;
}
.outline {
  background-color: transparent;
}
</style>
