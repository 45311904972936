<template>
  <div class="categories">
    <a
      @click="selectCategory(-1, null)"
      :class="[active == -1 ? 'active' : 'category']"
      href="#-1"
      id="category-1"
    >
      {{ $t("menu.all") }}
    </a>
    <a
      v-if="hasPopular"
      @click="selectCategory(0, null)"
      :class="[active == 0 ? 'active' : 'category']"
      href="#0"
    >
      {{ $t("menu.popular_dishes") }}
    </a>
    <a
      :class="[active == category.id ? 'active' : 'category']"
      v-for="(category, index) in customer_categories.data"
      :id="'category' + category.id"
      :key="index"
      :href="'#' + category.id"
    >
      {{ category.title }}
    </a>
  </div>
</template>

<script>
export default {
  mounted() {
    const vm = this;
    if (vm.$route.hash) {
      vm.selectCategory(vm.$route.hash.slice(1), null);
      if (vm.$route.hash.slice(1) >= 0) {
        setTimeout(() => {
          var element = document.getElementById(vm.$route.hash.slice(1));
          console.log(element);
          element.scrollIntoView();
        }, 500);
      }
    } else {
      this.selectCategory(-1, null);
    }
  },
  computed: {
    hasPopular() {
      return (
        this.popular_items.data.items &&
        this.popular_items.data.items.length != 0
      );
    },
  },
  watch: {
    $route(to, from) {
      if (to.hash) {
        this.active = to.hash ? to.hash.slice(1) : -1;
        this.selectCategory(this.active, null);
      }
    },
  },
  methods: {
    selectCategory(category_id, index) {
      this.$router.replace({ hash: category_id }).catch(() => {});

      this.active = category_id;

      if (this.active == -1) {
        this.gotoTop();
      }
    },
    gotoTop() {
      let view = document.getElementById("menu_content");

      view.scrollTop = 0;
    },
  },
  props: {
    categories: [],
    all_items: [],
    popular_items: [],
  },
  data() {
    return {
      active: 0,
    };
  },
};
</script>
<style scoped>
.categories {
  display: -webkit-box;
  display: flex;
  overflow-x: auto;
  padding: 8px 16px;
  min-height: 51px;
  max-width: 1540px;
  margin: 0px auto;
  width: 100%;
  /* scroll-behavior: smooth; */
}

.categories a {
  cursor: pointer;
  width: fit-content;
  min-width: fit-content;
  margin: 0px 5px 0px 0px;
  padding: 8px 12px;
  font-size: 16px;
  text-align: center;
}
.category {
  color: #b3b3b3;
}
.active {
  background-color: #fff7f5;
  color: #ff6a4d;
  font-weight: 500;
  font-size: 16px;
  display: flex;
  align-items: center;
  border-radius: 8px;
}
</style>
