export const createNewCart = ({ commit }, { basket }) => {
  commit('CREAT_NEW_CART', { basket })
}

export const addItemToCart = ({ commit }, { item, quantity }) => {
  commit('ADD_TO_CART', { item, quantity })
}

export const addExtraToCart = ({ commit }, { dish, index, extras }) => {
  commit('ADD_EXTRA_TO_CART', { dish, index, extras })
}

export const addCompulsoryToCart = ({ commit }, { dish, index, compulsories }) => {
  commit('ADD_COMPULSORY_TO_CART', { dish, index, compulsories })
}

export const addDrinkToCart = ({ commit }, { dish, index, drink }) => {
  commit('ADD_DRINK_TO_CART', { dish, index, drink })
}

export const addDeliveryCharge = ({ commit }, { delivery_charge }) => {
  commit('ADD_DELIVERY_CHARGE', { delivery_charge })
}

export const addDiscountToCart = ({ commit }, { discount }) => {
  commit('ADD_DISCOUNT_TO_CART', { discount })
}

export const removeItemFromCart = ({ commit }, { item, index }) => {
  commit('REMOVE_FROM_CART', { item, index })
}

export const clearCart = ({ commit }) => {
  commit('CLEAR_CART')
}

export const setOrderType = ({ commit }, { type }) => {
  commit('SET_ORDER_TYPE', { type })
}

export const setTable = ({ commit }, { table }) => {
  commit('SET_TABLE', { table })
}

export const setPaymentMethod = ({ commit }, { payment_method }) => {
  commit('SET_PAYMENT_METHOD', { payment_method })
}

export const setComment = ({ commit }, { comment }) => {
  commit('SET_COMMENT', { comment })
}

export const login = ({ commit }, { auth }) => {
  commit('LOGIN', { auth })
}

export const setQr = ({ commit }, { qr }) => {
  commit('SET_QR', { qr })
}

export const setOrderId = ({ commit }, { order_id }) => {
  commit('SET_ORDER_ID', { order_id })
}

export const setRestaurantName = ({ commit }, { restaurant }) => {
  commit('SET_RESTAURANT_NAME', { restaurant })
}

export const setName = ({ commit }, { name }) => {
  commit('SET_NAME', { name })
}

export const setEmail = ({ commit }, { email }) => {
  commit('SET_EMAIL', { email })
}

export const setToken = ({ commit }, { token }) => {
  commit('SET_TOKEN', { token })
}

export const setCartFromLocal = ({ commit }, { cart }) => {
  commit('SET_CART_FROM_LOCAL', { cart })
}

// just for restaurant
export const setUserId = ({ commit }, { user }) => {
  commit('SET_USER_ID', { user })
}
