<template>
  <div class="home">
    <div class="header">
      <div class="container">
        <div class="title">
          <div class="actions">
            <button @click="showModal('city_modal')" class="btn location">
              <div class="icon location"></div>
              <div class="city active-text">
                {{ selected_city.title ? selected_city.title : radius + " km" }}
              </div>
            </button>
          </div>
          <div class="banner"></div>
          <div class="actions">
            <router-link to="/scanner">
              <button class="btn icon qr-code"></button>
            </router-link>
            <button @click="install" class="btn install">
              <div
                :class="[installed ? '' : 'animation-blazer', 'blazer']"
              ></div>
              <div
                :class="[
                  installed ? '' : 'animation-install',
                  'icon',
                  'install',
                ]"
              ></div>
            </button>
          </div>
        </div>
        <div @click.prevent="gotoSearch" class="search">
          <div class="icon"></div>
          <input
            type="text"
            name="search"
            id="search"
            :placeholder="$t('customer_home.search_food_restaurant')"
          />
        </div>
      </div>
    </div>
    <div class="content">
      <h2>{{ $t("customer_home.top_rated") }}</h2>
      <restaurant-card
        v-for="(restaurant, index) in restaurants"
        :key="index"
        :restaurant="restaurant"
        v-if="restaurant.activated == 1"
      ></restaurant-card>
      <empty-restaurants v-if="restaurants.length == 0"></empty-restaurants>
    </div>

    <search-page
      v-if="search_page"
      @search_page="closeSearch"
      :city_id="selected_city.id"
    ></search-page>

    <div id="blur_background" class="blur-bg">
      <select-city-modal
        :cities="cities"
        @selected_city="selectedCity"
        @radius="setRadius"
        @geolocation="setGeolocation"
      ></select-city-modal>
      <install-guide-modal></install-guide-modal>
      <install-guide-android-modal></install-guide-android-modal>
    </div>
    <basket-btn></basket-btn>
  </div>
</template>
<script>
import BasketBtn from "../components/customer/BasketBtn.vue";
import InstallGuideAndroidModal from "../components/customer/InstallGuideAndroidModal.vue";
import InstallGuideModal from "../components/customer/InstallGuideModal.vue";
import RestaurantCard from "../components/customer/RestaurantCard.vue";
import SearchPage from "../components/customer/SearchPage.vue";
import SelectCityModal from "../components/customer/SelectCityModal.vue";
import EmptyRestaurants from "../components/customer/EmptyRestaurants.vue";

export default {
  components: {
    RestaurantCard,
    SearchPage,
    SelectCityModal,
    BasketBtn,
    InstallGuideModal,
    InstallGuideAndroidModal,
    EmptyRestaurants,
  },
  mounted() {
    this.installed = localStorage.installed;
    this.getNewUpdate();

    this.fetchCities();

    if (!localStorage.city) {
      this.showModal("city_modal");
    }

    if (this.$route.query.install) {
      this.install();
    }

    if (this.$route.query.discount) {
      localStorage.discount = this.$route.query.discount;
    }
  },
  methods: {
    gotoSearch() {
      this.search_page = true;
    },
    closeSearch(val) {
      this.search_page = val;
      this.selectedCity(JSON.parse(localStorage.city));
    },
    selectedCity(val) {
      this.selected_city = val;
      this.radius = 0;
      this.fetchTopRestaurant();
    },
    setRadius(val) {
      this.selected_city = {};
      this.radius = val;
      if (this.my_location.data.latitude) {
        this.fetchTopRestaurantByRadius();
      }
    },
    setGeolocation(val) {
      this.selected_city = {};
      if (this.my_location.data.latitude) {
        this.fetchTopRestaurantByRadius();
      }
    },
    fetchTopRestaurantByRadius() {
      // this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname +
            "/top-restaurants?latitude=" +
            this.my_location.data.latitude +
            "&longitude=" +
            this.my_location.data.longitude +
            "&max_distance=" +
            this.radius
        )
        .then((resp) => {
          this.successRequest();

          this.restaurants = resp.data.restaurants;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchTopRestaurant() {
      // this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/top-restaurants?city=" + this.selected_city.id)
        .then((resp) => {
          this.successRequest();
          console.log(resp.data.restaurants);

          this.restaurants = resp.data.restaurants;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchCities() {
      if (localStorage.city) {
        this.selected_city = JSON.parse(localStorage.city);
      } else {
        this.selected_city = this.cities[2];
      }
      this.fetchTopRestaurant();
    },
    install() {
      this.installed = true;
      localStorage.installed = this.installed;
      if (this.isItiOS()) {
        window.location =
          "https://apps.apple.com/app/nordantia-tilaa/id6462874669";

        // this.showModal("install_guide_modal");
      } else {
        window.top.location.href =
          "https://play.google.com/store/apps/details?id=com.nordantia.tilaa.twa";

        // this.showModal("install_guide_android_modal");
      }
    },
  },
  data() {
    return {
      installed: false,
      selected_city: {},
      search_page: false,
      restaurants: [],
      radius: 0,
      geolocation: {
        latitude: "",
        longitude: "",
        radius: 0,
      },
    };
  },
};
</script>
<style scoped>
.home {
  padding-bottom: 196px;
  /* overflow: hidden; */
  max-height: 100vh;
}
.container {
  flex-direction: column;
  gap: 12px;
}
.header .title {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.banner {
  background-image: url("../assets/common/nordantia_customer.svg");
}
.content {
  gap: 20px;
  padding: 140px 16px 128px;
  height: 100%;
  /* overflow-y: scroll; */
}

.actions {
  display: flex;
  width: 100px;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
}

.search,
.actions button {
  background-color: #f5f6f7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 40px;
}

input#search {
  width: 100%;
}
button.location {
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
}

button.location .location {
  width: 20px;
  background-image: url("../assets/customer/restaurant/location.svg");
}

button.install {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 14px;
  width: 40px;
  background-color: #fff7f5;
}

button.install .install {
  position: absolute;
  width: 40px;
  background-image: url("../assets/customer/restaurant/install.svg");
}
.animation-install {
  -webkit-animation: iconreverse 1.5s 0.2s ease-in-out infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: iconreverse 1.5s 0.2s ease-in-out infinite;
  -moz-transform-origin: 50% 50%;
  animation: iconreverse 1.5s 0.2s ease-in-out infinite;
  transform-origin: 50% 50%;
}
button.qr-code {
  width: 40px;
  background-image: url("../assets/customer/restaurant/qr.svg");
}

h2 {
  font-weight: 400;
  font-size: 16px;
  color: #4d4d4d;
}

.city {
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14px;
}

.blazer {
  height: 100%;
  width: 100%;
  border-radius: 50%;
  transform: scale(0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ff6a4d;
  /* background-blend-mode: color-dodge; */
}

.animation-blazer {
  -webkit-animation: heart 1.5s 0.2s ease-in-out infinite;
  -webkit-transform-origin: 50% 50%;
  -moz-animation: heart 1.5s 0.2s ease-in-out infinite;
  -moz-transform-origin: 50% 50%;
  animation: heart 1.5s 0.2s ease-in-out infinite;
  transform-origin: 50% 50%;
}

@keyframes heart {
  0% {
    border-radius: 50%;
    transform: scale(0, 0);
  }

  50% {
    border-radius: 8px;
    transform: scale(1, 1);
  }

  100% {
    border-radius: 50%;
    transform: scale(0, 0);
  }
}

@keyframes iconreverse {
  0% {
    background-image: url("../assets/customer/restaurant/install.svg");
  }

  20% {
    background-image: url("../assets/customer/restaurant/install.svg");
  }

  40% {
    background-image: url("../assets/customer/restaurant/install_white.svg");
  }

  60% {
    background-image: url("../assets/customer/restaurant/install_white.svg");
  }

  80% {
    background-image: url("../assets/customer/restaurant/install.svg");
  }

  100% {
    background-image: url("../assets/customer/restaurant/install.svg");
  }
}
</style>
