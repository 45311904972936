<template>
  <div class="history">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/reservation'"></back-button>

        <div class="title">{{ $t("navbar.reservation") }}</div>
        <span></span>
      </div>
    </div>
    <div class="content">
      <div class="reservation">
        <router-link :to="'/menu/' + reservation.menu" class="btn restaurant">
          <div
            class="photo"
            :style="[
              reservation.photo
                ? {
                    'background-image': 'url(' + reservation.photo + ')',
                  }
                : '',
            ]"
          ></div>
          <div class="detail">
            <div class="head">
              <h3>
                {{ reservation.restaurant }}
              </h3>
              <div
                :class="[reservation.status == 1 ? 'open' : 'closed', 'status']"
              >
                {{
                  reservation.status == 1 ? $t("menu.open") : $t("menu.closed")
                }}
              </div>
            </div>
            <div class="type">{{ reservation.type }}</div>
            <div class="footer">
              <div class="rate">
                <div class="icon star"></div>
                <span>
                  {{ reservation.average + " / 5" }}
                </span>
              </div>
            </div>
          </div>
        </router-link>
        <div class="reservation info">
          <div class="row">
            <div class="caption">{{ $t("reserve.date_time") + " :" }}</div>
            <div class="data">{{ formatDatetime(reservation.datetime) }}</div>
          </div>
          <div class="row">
            <div class="caption">{{ $t("reserve.date_time") + " :" }}</div>
            <div class="data">
              {{ formatDatetime(reservation.reserved_end) }}
            </div>
          </div>
          <div class="row">
            <div class="caption">{{ $t("reserve.no_people") + " :" }}</div>
            <div class="data">
              {{ reservation.people + $t("reserve.people") }}
            </div>
          </div>
          <div class="row">
            <div class="caption">{{ reservation.full_name }}</div>
            <div class="data">{{ reservation.email }}</div>
          </div>
          <div class="row">
            <div class="caption">
              {{
                $t("order.table") +
                ": " +
                (reservation.table
                  ? reservation.table
                  : reservation.table_combination)
              }}
            </div>
            <div class="data">{{ reservation.phone_number }}</div>
          </div>
          <div class="row">
            <div :class="[reservation.reservation_status]">
              {{
                reservation.reservation_status == "cancelled"
                  ? "Cancelled"
                  : reservation.reservation_status == "pending"
                  ? $t("reserve.pending")
                  : $t("reserve.confirmed")
              }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import BackButton from "../components/common/BackButton.vue";

export default {
  mounted() {
    this.fetch();
  },
  components: {
    BackButton,
  },
  methods: {
    fetch() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/reservations/" + this.token, this.token.config)
        .then((resp) => {
          this.successRequest();
          this.reservation = resp.data.result;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  props: {
    token: "",
  },
  data() {
    return {
      reservation: {},
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}
.content {
  padding: 70px 16px 110px;
  height: 88vh;
  gap: 8px;
}

.reservation {
  transition: 0.3s ease;
  overflow: hidden;
  background-color: #f5f6f7;
  border-radius: 8px;
  gap: 12px;
}

.restaurant {
  justify-content: flex-start;
  gap: 12px;
  padding: 12px;
  cursor: pointer;
}

h3 {
  color: #4d4d4d;
  width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 64%;
  width: 70%;
}

.head {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.type {
  color: #b3b3b3;
  font-size: 14px;
  width: 100%;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.photo {
  min-width: 86px;
  width: 30%;
  height: 82px;
  border-radius: 8px;
  background-image: url("../assets/customer/restaurant/restaurant.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.down {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  width: 24px;
  transition: 0.3s ease;
  background-image: url("../assets/common/down.svg");
}

.row {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.caption {
  font-weight: 300;
  font-size: 14px;
  color: #737373;
}

.data {
  font-size: 14px;
  color: #776282;
}
.info {
  border-top: 1px solid #f0edf2;
  display: flex;
  flex-direction: column;
  padding: 12px;
}

.pending {
  background-color: #f7f31849;
  color: #bcb601;
  padding: 12px 6px;
  border-radius: 12px;
  text-transform: capitalize;
  text-align: center;
  height: 42px;
  width: 100%;
}
.confirmed {
  background-color: #36f71849;
  color: #01bc0d;
  padding: 12px 6px;
  border-radius: 12px;
  text-transform: capitalize;
  text-align: center;
  width: 100%;
  height: 42px;
}

.cancelled {
  background-color: #f7181849;
  color: #bc0101;
  padding: 12px 6px;
  border-radius: 12px;
  text-transform: capitalize;
  text-align: center;
  width: 100%;
  height: 42px;
}
</style>
