<template>
  <div class="camera">
    <qrcode-stream
      :track="paintBoundingBox"
      :camera="'auto'"
      @decode="onDecode"
      @init="onInit"
    >
      <div v-if="validationSuccess" class="validation-success"></div>
    </qrcode-stream>
    <qrcode-drop-zone></qrcode-drop-zone>
    <div>{{ permission }}</div>
  </div>
</template>

<script>
import { QrcodeStream, QrcodeDropZone } from "vue-qrcode-reader";

export default {
  name: "QRCodeReader",
  mounted() {},
  methods: {
    paintBoundingBox(detectedCodes, ctx) {
      for (const detectedCode of detectedCodes) {
        const {
          boundingBox: { x, y, width, height },
        } = detectedCode;

        ctx.lineWidth = 2;
        ctx.strokeStyle = "#007bff";
        ctx.strokeRect(x, y, width, height);
      }
    },
    async onInit(promise) {
      try {
        const { capabilities } = await promise;
      } catch (error) {
        this.permission = error;
      }
      promise.catch(console.error).then(this.resetValidationState);
    },

    resetValidationState() {
      this.isValid = undefined;
    },

    async onDecode(content) {
      this.result = content;

      this.turnCameraOff();

      // pretend it's taking really long
      await this.timeout(1000);
      this.isValid = content.startsWith("http");

      // some more delay, so users have time to read the message
      await this.timeout(1000);
      //substr(41) for test server and substr(36) for localhost
      // this.$store.dispatch("setQr", {
      //   qr: this.result.substr(41),
      // });

      // localStorage.qr = this.result.substr(32);
      // this.$store.dispatch("clearCart");
      window.location.replace(this.result);

      this.turnCameraOn();
    },

    turnCameraOn() {
      this.camera = "auto";
    },

    turnCameraOff() {
      this.camera = "off";
    },

    timeout(ms) {
      return new Promise((resolve) => {
        window.setTimeout(resolve, ms);
      });
    },
  },
  computed: {
    validationPending() {
      return this.isValid === undefined && this.camera === "off";
    },

    validationSuccess() {
      return this.isValid === true;
    },

    validationFailure() {
      return this.isValid === false;
    },
  },
  components: {
    QrcodeStream,
    QrcodeDropZone,
  },
  data() {
    return {
      isValid: undefined,
      camera: "rear",
      result: "",
      permission: null,
    };
  },
};
</script>
<style scoped>
.camera {
  max-height: 100%;
}
</style>