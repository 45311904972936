<template>
  <div id="reservation_modal" class="modal">
    <div class="question">
      <h2>{{ $t("reserve.table_reserve") }}</h2>

      <div class="people">
        <div class="input">
          <label for="reservation-people">{{ $t("reserve.no_people") }}</label>
          <select
            v-model="people"
            id="reservation-people"
            @change="clearDateTime()"
          >
            <option v-for="counter in capacity_range" :value="counter">
              {{ counter }}
            </option>
          </select>
        </div>

        <div class="input reservation-date">
          <label for="reservation-date">{{ $t("history.date") }}</label>
          <date-picker
            lang="fi"
            v-model="date"
            :default-value="new Date()"
            :disabled-date="disabledRange"
            type="date"
            value-type="format"
            format="YYYY-MM-DD"
            :min-date="new Date()"
            @change="clearTime()"
          />
        </div>

        <div v-if="not_found_times" class="warning">
          There are no tables available on this day
        </div>

        <div class="input">
          <label for="reservation-time">{{ $t("history.time") }}</label>
          <select @change="fetchPeriods()" v-model="time" id="reservation-time">
            <option v-for="hour in hours">
              {{ hour }}
            </option>
          </select>
        </div>

        <div class="warning">
          {{ "*" + important_note }}
        </div>
      </div>

      <div class="options">
        <div
          @click="setTableId(period)"
          class="reservation-input"
          v-for="(period, index) in periods"
          :key="index"
        >
          <div
            v-if="validHours(index)"
            :class="[
              selected_time == index &&
              (table_id != 0 || table_combination_id != 0)
                ? 'option-selected'
                : 'option',
              period.tables[0] || period.table_combinations[0]
                ? 'option'
                : 'option-off',
            ]"
          >
            <input
              name="reservation"
              v-model="selected_time"
              :value="index"
              type="radio"
              hidden
              :id="'option' + index"
            />
            <label :for="'option' + index">{{ formatTimestamp(index) }}</label>
          </div>
        </div>
      </div>

      <div v-if="table_id !== 0 || table_combination_id !== 0" class="form">
        <div class="input reservation-text-input">
          <label for="reservation-name">*{{ $t("registration.name") }}</label>
          <input v-model="full_name" type="text" id="reservation-name" />
        </div>

        <div class="input reservation-text-input">
          <label for="reservation-email">*{{ $t("registration.email") }}</label>
          <input v-model="email" type="email" id="reservation-email" />
        </div>

        <div class="input reservation-text-input">
          <label for="reservation-phone"
            >*{{ $t("information.phone_number") }}</label
          >
          <input v-model="phone_number" type="text" id="reservation-phone" />
        </div>

        <div class="input reservation-text-input">
          <label for="reservation-message">{{ $t("menu.add_request") }}</label>
          <textarea v-model="message" id="reservation-message" />
        </div>
      </div>
    </div>

    <button @click="submitReservation" class="fill">
      {{ $t("reserve.submit") }}
    </button>
  </div>
</template>
<script>
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/fi";

export default {
  components: {
    DatePicker,
  },
  mounted() {
    this.fetchReservationConfig();

    let aux = new Date();
    this.today =
      aux.getFullYear() +
      "-" +
      (aux.getMonth() + 1 >= 10
        ? aux.getMonth() + 1
        : "0" + (aux.getMonth() + 1)) +
      "-" +
      (aux.getDate() >= 10 ? aux.getDate() : "0" + aux.getDate());
  },
  data() {
    return {
      people: 1,
      reserved_at: "",
      today: "",
      select_time_reservation_modal: false,
      type: "", //table|combo
      today: "",
      full_name: "",
      email: "",
      message: "",
      phone_number: "",
      table_id: 0,
      table_combination_id: 0,
      people: 1,
      date: "",
      time: "",
      periods: [],
      selected_time: {},
      hours: [],
      valid_hours: [],
      capacity_range: [],
      important_note: "",
      value1: new Date(),
      invalid_times: [],
      not_found_times: false,
    };
  },
  props: {
    restaurant_id: "",
  },
  methods: {
    disabledRange: function (date) {
      if (this.invalid_times.length) {
        return (
          date < new Date() ||
          (date > new Date(this.invalid_times[0].start_at) &&
            date < new Date(this.invalid_times[0].end_at))
        );
      }

      return false;
    },
    fetchReservationConfig() {
      this.$http
        .get(this.$hostname + "/reservation-config?qr=" + localStorage.qr)
        .then((resp) => {
          this.invalid_times = resp.data.result.invalid_times;
          this.important_note = resp.data.result.reservation_note;
          this.setCapacityRange(
            resp.data.result.max_capacity,
            resp.data.result.min_capacity
          );
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validHours(hour) {
      let now = new Date();
      let time = new Date(hour);
      return time - now >= 600000;
    },
    clearTime() {
      this.time = "";
      this.selected_time = {};
      this.periods = [];
      this.fetchReservationWorkingHours();
    },
    clearDateTime() {
      this.date = "";
      this.clearTime();
    },
    setCapacityRange(max_capacity, min_capacity) {
      this.capacity_range = Array.apply(null, {
        length: max_capacity + 1 - min_capacity,
      }).map(function (_, idx) {
        return idx + min_capacity;
      });
    },
    fetchReservationWorkingHours() {
      if (this.date) {
        this.$http
          .get(
            this.$hostname +
              "/working-hours?qr=" +
              localStorage.qr +
              "&day=" +
              this.date
          )
          .then((resp) => {
            this.hours = resp.data.result;
            this.not_found_times = false;

            if (resp.data.result.length == 0) {
              this.not_found_times = true;
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    fetchPeriods() {
      this.$http
        .get(
          this.$hostname +
            "/calendar/reservations?people=" +
            this.people +
            "&qr=" +
            localStorage.qr +
            "&reserve_at=" +
            this.date +
            " " +
            this.time
        )
        .then((resp) => {
          this.periods = resp.data.result.periods;
          this.restaurant_id = resp.data.result.restaurant_id;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    formatTimestamp(datetime) {
      const formatedTime = new Date(datetime);
      return (
        formatedTime.getHours() +
        ":" +
        (formatedTime.getMinutes() == 0 ? "00" : formatedTime.getMinutes())
      );
    },
    setTableId(period) {
      if (period.tables[0]) {
        this.table_id = period.tables[0];
        this.table_combination_id = 0;

        this.type = "table";
      } else if (period.table_combinations[0]) {
        this.table_combination_id = period.table_combinations[0];
        this.table_id = 0;

        this.type = "combo";
      } else {
        this.table_combination_id = 0;
        this.table_id = 0;
      }
    },
    submitReservation() {
      let data = {
        full_name: this.full_name,
        email: this.email,
        message: this.message,
        phone_number: this.phone_number,
        people: this.people,
        restaurant_id: this.restaurant_id,
        datetime: this.selected_time,
        table_id: this.table_id,
        table_combination_id: this.table_combination_id,
        source_id: 3,
      };
      this.$http
        .post(this.$hostname + "/reservations", data)
        .then((resp) => {
          this.successRequest();
          this.closeModal("reservation_modal");
          this.global_success.message = this.$t("reserve.success_message");

          setTimeout(() => {
            this.showModal("succeed_modal");
          }, 500);

          setTimeout(() => {
            this.closeModal("succeed_modal");
            this.$router.push("/reservation/" + resp.data.reserve.token);
          }, 4000);
        })
        .catch((err) => {
          console.log(err.response);
          this.showErrors(err);
        });
    },
    done() {
      let data = {
        date: this.date,
        time: this.reservedTime,
        people: this.people,
        restaurant_id: this.restaurant_id,
      };
      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/client/reservations", data, this.token.config)
        .then((resp) => {
          this.successRequest();
          this.closeModal("reservation_modal");

          this.global_success.message = this.$t("reserve.success_message");

          setTimeout(() => {
            this.showModal("succeed_modal");
          }, 500);

          setTimeout(() => {
            this.closeModal("succeed_modal");
          }, 4000);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.modal {
  gap: 12px;
  justify-content: flex-end;
}

.question {
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 4px;
  height: 530px;
  overflow-y: scroll;
  gap: 8px;
}

h2 {
  font-size: 18px;
  text-align: center;
  color: #737373;
  padding: 16px 0 8px;
  font-weight: normal;
}

.desc {
  font-size: 14px;
  color: #b3b3b3;
  padding: 12px 0 0;
}

.people {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 12px;
  border-top: 1px solid #f5f6f7;
}

.options {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
}

.options label {
  background-color: #f8f9fa;
  border-radius: 8px;
  width: 76px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #4d4d4d;
  cursor: pointer;
}

input[type="radio"]:checked + label {
  border: 1px solid #ff6a4d;
}

input[type="radio"] {
  appearance: none;
  display: none;
}

.warning {
  font-size: 14px;
  color: #ff6a4d;
}

button {
  width: 100%;
  border-radius: 16px;
}
.input {
  padding-right: 12px;
  justify-content: space-between;
}
.input label {
  width: 60px;
}

.option-off {
  text-decoration: line-through;
}

select {
  width: calc(100% - 60px);
}

textarea {
  width: calc(100% - 60px);
}

input.mx-input {
  background-color: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}
</style>
