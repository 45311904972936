import Vue from "vue";
import moment from "moment";

export default Vue.mixin({
  updated() {
    const every_input = document.querySelectorAll("input[type=text]");
    for (let i = 0; i < every_input.length; i++) {
      every_input[i].addEventListener("focus", () => {
        this.hideNav();
      });
      every_input[i].addEventListener("blur", () => {
        this.showNav();
      });
    }
    const every_textarea = document.getElementsByTagName("textarea");
    for (let i = 0; i < every_textarea.length; i++) {
      every_textarea[i].addEventListener("focus", () => {
        this.hideNav();
      });
      every_textarea[i].addEventListener("blur", () => {
        this.showNav();
      });
    }
  },
  watch: {
    $route(to, from) {
      if (!to.query.modal && from.query.modal) {
        this.closeModal(from.query.modal);
      }
    },
  },
  methods: {
    openInstalliOSApp() {
      if (this.isItiOS() && localStorage.token) {
        window.location =
          "com.nordantia.tilaa://nordantia/home?token=" + localStorage.token;
      }
    },
    getNewUpdate() {
      this.$http
        .get(this.$hostname + "/latest-version")
        .then((resp) => {
          if (
            localStorage.latest_version &&
            resp.data.version != localStorage.latest_version
          ) {
            if (confirm("New version of the app is available. Refresh now?")) {
              localStorage.latest_version = resp.data.version;
              window.location.reload(true);
            }
          } else {
            localStorage.latest_version = resp.data.version;
          }
        })
        .catch((err) => {
          console.error(err.response);
        });
    },
    whereAreYou() {
      navigator.geolocation.getCurrentPosition(this.showPosition);
      // if (navigator.geolocation) {
      // }
    },
    showPosition(position) {
      this.my_location.data.latitude = position.coords.latitude;
      this.my_location.data.longitude = position.coords.longitude;

      localStorage.latitude = position.coords.latitude;
      localStorage.longitude = position.coords.longitude;

      this.findAddressByCoordinate();
    },
    findAddressByCoordinate() {
      if (localStorage.token) {
        this.$http
          .get(
            this.$hostname +
              "/client/find-address?latitude=" +
              localStorage.latitude +
              "&longitude=" +
              localStorage.longitude,
            this.token.config
          )
          .then((resp) => {
            let full_address = resp.data.address.formatted_address.split(",");
            let second_address = full_address[1].split(" ");

            localStorage.address_desc = full_address[0];
            localStorage.address_zip_code = second_address[1];
            localStorage.address_city = second_address[2];
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
    decodeUserIdByToken() {
      if (localStorage.restaurant_token || localStorage.token) {
        if (localStorage.restaurant_token) {
          var base64Url = localStorage.restaurant_token.split(".")[1];
        } else if (localStorage.token) {
          var base64Url = localStorage.token.split(".")[1];
        }
        var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
        var jsonPayload = decodeURIComponent(
          window
            .atob(base64)
            .split("")
            .map(function (c) {
              return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join("")
        );
        // console.log(jsonPayload);

        return JSON.parse(jsonPayload).sub;
      }
    },
    isItiOS() {
      return (
        navigator.vendor.match(/apple/i) &&
        !navigator.userAgent.match(/crios/i) &&
        !navigator.userAgent.match(/fxios/i) &&
        !navigator.userAgent.match(/Opera|OPT\//)
      );
    },
    iOSBottomGapFixer(class_name) {
      const actualHeight = window.innerHeight;
      const elementHeight = document.querySelector(class_name).clientHeight;

      const barHeight = elementHeight - actualHeight;

      let isSafari =
        navigator.vendor.match(/apple/i) &&
        !navigator.userAgent.match(/crios/i) &&
        !navigator.userAgent.match(/fxios/i) &&
        !navigator.userAgent.match(/Opera|OPT\//);

      let container = document.querySelector(class_name);

      if (isSafari) {
        // Safari browser is used
        container.style.paddingBottom = "80px";
      } else {
        if (barHeight > 0) {
          container.style.height = `${actualHeight}px`;
        }
        // Other browser is used
      }
    },
    hideNav() {
      document.getElementById("navigation").style.display = "none";
    },
    showNav() {
      document.getElementById("navigation").style.display = "flex";
    },
    google() {
      window.top.location.href = this.$public_server + "/auth/google";
    },
    showLanguages() {
      let open = document.getElementById("open_languages");
      let languages = document.getElementById("languages");

      if (!languages.style.height || languages.style.height == "50px") {
        languages.style.height = "0px";

        // languages.style.display = 'none'

        open.style.transform = "rotate(0deg)";

        return false;
      }
      if (languages.style.height == "0px") {
        languages.style.height = "50px";

        // languages.style.display = 'flex'

        open.style.transform = "rotate(180deg)";

        return true;
      }
    },
    toggleShowPassword(id_password) {
      let password = document.getElementById(id_password);

      if (password.type == "password") {
        password.type = "text";
        return false;
      } else {
        password.type = "password";
        return true;
      }
    },
    showModal(modal_id) {
      if (modal_id != "reset_password_modal") {
        this.$router
          .push({
            query: { modal: modal_id },
          })
          .catch(() => {});
      }

      let modal = document.getElementById(modal_id);

      const blur_background = document.getElementById("blur_background");

      blur_background.style.display = "block";

      // this.blur_background.show = true

      modal.style.display = "flex";
      setTimeout(() => {
        modal.style.bottom = "0%";
      }, 100);

      // When the user clicks anywhere outside of the modal, close it
      if (this.isItiOS()) {
        blur_background.addEventListener(
          "click",
          (event) => {
            if (event.target.id == "blur_background") {
              this.$router.replace({ query: null }).catch(() => {});

              setTimeout(() => {
                modal.style.bottom = "-100%";
              }, 100);
              setTimeout(() => {
                modal.style.display = "none";
                blur_background.style.display = "none";
                // this.blur_background.show = false
              }, 300);
              return 0;
            }
          }
          // },
          // false
        );
      } else {
        blur_background.addEventListener(
          "click",
          (event) => {
            if (event.target.id == "blur_background") {
              this.$router.replace({ query: null }).catch(() => {});

              setTimeout(() => {
                modal.style.bottom = "-100%";
              }, 100);
              setTimeout(() => {
                modal.style.display = "none";

                blur_background.style.display = "none";

                // blur_background.style.left = '-100%'
                // this.blur_background.show = false
              }, 300);
              return;
            }
          },
          false
        );
      }
    },
    closeModal(modal_id) {
      let modal = document.getElementById(modal_id);

      this.$router.replace({ query: null }).catch(() => {});

      const blur_background = document.getElementById("blur_background");

      setTimeout(() => {
        modal.style.bottom = "-100%";
      }, 100);

      setTimeout(() => {
        modal.style.display = "none";

        blur_background.style.display = "none";
      }, 300);
    },
    openLoginModal(redirect_to) {
      this.$router
        .push({
          query: { login: true },
        })
        .catch(() => {});
      localStorage.redirect_to = redirect_to;
      this.showModal("login_modal");
    },
    openSignupModal(redirect_to) {
      this.$router
        .push({
          query: { login: true },
        })
        .catch(() => {});
      localStorage.redirect_to = redirect_to;

      this.showModal("signup_modal");
    },
    openItemModal() {
      // if (this.$route.name == 'Menu') {
      //   this.$router
      //     .replace(
      //       `/customer/menu/${this.$store.state.qr}?item=${this.selecting_item.data.id}`
      //     )
      //     .catch(() => {})
      // }
      this.$router
        .push({
          query: { modal: "extras_modal" },
        })
        .catch(() => {});

      let modal = document.getElementById("extras_modal");

      const blur_background = document.getElementById("blur_background");

      blur_background.style.display = "block";

      modal.style.display = "flex";
      setTimeout(() => {
        modal.style.bottom = "0%";
      }, 100);
    },
    // expand compulsory in extra modal
    expandCompulsory(index, last_index) {
      let open = document.getElementById("showmore-" + index);
      let arrow = document.getElementById("arrowmore-" + index);
      let box = document.getElementById("compulsories-" + index);

      if (box.style.height == "auto") {
        box.style.height = "136px";

        arrow.style.transform = "rotate(0deg)";

        open.innerHTML = this.$t("menu.show_all");

        return false;
      } else {
        box.style.height = "auto";

        arrow.style.transform = "rotate(180deg)";

        open.innerHTML = this.$t("menu.show_less");
        this.closeOtherCompulsory(index, last_index);

        return true;
      }
    },
    closeOtherCompulsory(index, last_index) {
      for (let j = 0; j < last_index - 1; j++) {
        if (j != index) {
          let open = document.getElementById("showmore-" + j);
          let arrow = document.getElementById("arrowmore-" + j);
          let box = document.getElementById("compulsories-" + j);
          if (arrow) {
            box.style.height = "136px";
            arrow.style.transform = "rotate(0deg)";
            open.innerHTML = this.$t("menu.show_all");
          }
        }
      }
    },
    closeAllCompulsory(compulsory_length) {
      for (let j = 0; j < compulsory_length; j++) {
        let open = document.getElementById("showmore-" + j);
        let arrow = document.getElementById("arrowmore-" + j);
        let box = document.getElementById("compulsories-" + j);
        if (arrow) {
          box.style.height = "136px";
          arrow.style.transform = "rotate(0deg)";
          open.innerHTML = this.$t("menu.show_all");
        }
      }
    },
    toggleSwitch(option) {
      let switcher = document.getElementById("switch_selector");
      if (option) {
        switcher.style.left = "49.5%";
      } else {
        switcher.style.left = "4px";
      }
      return !option;
    },
    tripleSwitch(option) {
      let switcher = document.getElementById("switch_selector");
      if (option == 2) {
        switcher.style.left = "33%";
      } else if (option == 1) {
        switcher.style.left = "4px";
      } else {
        switcher.style.left = "65%";
      }
      return option;
    },
    formatPrice(price) {
      price = price
        ? Number.isInteger(price)
          ? price
          : parseFloat(price).toFixed(2)
        : "0,00";
      return price.toString().replace(".", ",") + " €";
    },
    replaceDot(price) {
      price = price ? parseFloat(price).toFixed(2) : "";
      return price.toString().replace(".", ",");
    },
    replaceComma(price) {
      return parseFloat(price.toString().replace(",", "."));
    },
    totalPrice(items) {
      let total = 0;

      if (items) {
        items.forEach((element) => {
          total +=
            element.item.price -
            (element.item.price * element.item.discount) / 100;

          if (element.drinks.length) {
            element.drinks.forEach((drink) => {
              total += drink.price;
            });
          }

          if (element.extras.length) {
            element.extras.forEach((extra) => {
              total += extra.price;
            });
          }

          if (element.compulsories.length) {
            element.compulsories.forEach((compulsory) => {
              compulsory.sub_compulsories.forEach((sub_compulsory) => {
                total += sub_compulsory.price;
              });
            });
          }
        });
      }

      return parseFloat(total).toFixed(2);
    },
    setNightShiftCharge(
      start_night_shift,
      end_night_shift,
      night_shift_charge
    ) {
      let now = new Date();

      return (parseInt(now.getHours()) >=
        parseInt(start_night_shift.substring(0, 2)) &&
        parseInt(now.getMinutes()) >=
          parseInt(start_night_shift.substring(3, 5))) ||
        (parseInt(now.getHours()) <= parseInt(end_night_shift.substr(0, 2)) &&
          parseInt(now.getMinutes()) <= parseInt(end_night_shift.substr(3, 5)))
        ? night_shift_charge
        : 0;
    },
    totalInBasket() {
      let total = 0;

      if (this.$store.state.cart.length && this.$store.state.cart.length > 0) {
        this.$store.state.cart.forEach((element) => {
          if (element.item.discount && element.item.discount > 0) {
            total +=
              element.item.price -
              (element.item.price * element.item.discount) / 100;
          } else {
            total += element.item.price;
          }
          if (element.drinks.length) {
            element.drinks.forEach((drink) => {
              total += drink.price;
            });
          }
          if (element.extras.length) {
            element.extras.forEach((extra) => {
              total += extra.price;
            });
          }

          if (element.compulsories.length) {
            element.compulsories.forEach((compulsory) => {
              compulsory.sub_compulsories.forEach((sub_compulsory) => {
                if (sub_compulsory.price > 0) {
                  total += sub_compulsory.price;
                }
              });
            });
          }
        });
      }

      return parseFloat(total).toFixed(2);
    },
    formatDatetime(datetime) {
      return moment(datetime).format("D.M.YYYY - HH:mm");
    },
    formatDate(datetime) {
      return moment(datetime).format("D.M.YYYY");
    },
    gotoHome() {
      this.$router.push("/");
    },
    printOrder(order) {
      {
        // window.print();
        var mywindow = window.open("", "PRINT", "height=400,width=600");

        mywindow.document.write(
          "<html><head><title>https://nordantia.com</title></head><body>"
        );

        mywindow.document.write("<center><h3>Nordantia #" + order.id + "</h3>");

        mywindow.document.write("<hr/>");

        mywindow.document.write("<h4>" + order.restaurant.title + "</h4>");
        mywindow.document.write(
          "<h4>" + this.formatDatetime(order.created_at) + "</h4>"
        );

        mywindow.document.write("<hr/>");

        if (order.user) {
          mywindow.document.write(`<p>` + order.user.name + "</p>");
          mywindow.document.write(`<p>` + order.user.email + "</p>");
          if (order.user.phone_number) {
            mywindow.document.write(`<p>` + order.user.phone_number + "</p>");
          }

          if (order.order_type == "delivery") {
            mywindow.document.write(
              `<p>${this.$t("account.address")}: ` +
                order.address.description +
                (order.address.zip_code ? ", " + order.address.zip_code : "") +
                (order.address.city ? " " + order.address.city : "") +
                "</p>"
            );
            mywindow.document.write(
              `<p>${this.$t("information.door_code")}: ` +
                order.address.door_code +
                "</p>"
            );

            mywindow.document.write(
              `<p>${this.$t("information.phone_number")}: ` +
                order.address.phone_number +
                "</p>"
            );
            mywindow.document.write(
              `<p>${this.$t("order.delivery_fee")}: ` +
                this.formatPrice(order.delivery_charge) +
                "</p>"
            );
          }

          if (order.night_shift_charge > 0) {
            mywindow.document.write(
              `<p>${this.$t("order.night_shift")} ` +
                this.formatPrice(order.night_shift_charge) +
                "</p>"
            );
          }

        

          mywindow.document.write("<hr/>");
        }

        mywindow.document.write(
          "<p>" +
            this.$t("order.payment_method") +
            (order.payment_method == "mobile"
              ? "Mobile"
              : order.payment_method == "online"
              ? "Online"
              : order.payment_method == "cash"
              ? this.$t("order.cash_pay")
              : order.payment_method == "at_place"
              ? this.$t("cart.at_place")
              : "Not defined") +
            "<span>(" +
            (order.payment_status == 1
              ? this.$t("order.paid")
              : this.$t("cart.unpaid")) +
            ")</span>" +
            "</p>"
        );

        mywindow.document.write(
          "<p>" +
            this.$t("order.order_type") +
            ": " +
            (order.order_type == "pick_up"
              ? this.$t("order.pick_up")
              : order.order_type == "delivery"
              ? this.$t("order.delivery")
              : this.$t("order.at_restaurant") +
                " " +
                this.$t("order.table") +
                " " +
                order.table_number) +
            "</p>"
        );

        mywindow.document.write("</center><hr/>");
        mywindow.document.write("<hr/>");
        mywindow.document.write(
          order.json_items.length + " " + this.$t("order.portions")
        );
        mywindow.document.write("<hr/>");

        mywindow.document.write("<dl>");

        order.json_items.forEach((element) => {
          mywindow.document.write(
            "<dt>" +
              element.item.title +
              (element.item.category
                ? " (" + element.item.category.title + ")"
                : "") +
              " " +
              (element.item.discount > 0
                ? `<s>${this.formatPrice(element.item.price)}</s>` +
                  this.formatPrice(
                    element.item.price -
                      (element.item.price * element.item.discount) / 100
                  )
                : this.formatPrice(element.item.price)) +
              "</dt>"
          );

          if (element.extras.length) {
            mywindow.document.write(
              "<dd> " + this.$t("cart.extras") + ":</dd>"
            );
            element.extras.forEach((extra) => {
              mywindow.document.write(
                "<dd> " +
                  extra.title +
                  " " +
                  this.formatPrice(extra.price) +
                  "</dd>"
              );
            });
          }

          if (element.compulsories.length) {
            element.compulsories.forEach((compulsory) => {
              mywindow.document.write("<dd> " + compulsory.title + ": ");
              compulsory.sub_compulsories.forEach((sub_compulsory) => {
                mywindow.document.write(
                  "<div> -" +
                    sub_compulsory.title +
                    " " +
                    this.formatPrice(sub_compulsory.price) +
                    "</div>"
                );
              });
              mywindow.document.write("</dd>");
            });
          }

          if (element.drinks.length) {
            mywindow.document.write(
              "<dd> " + this.$t("cart.drinks") + ":</dd>"
            );

            element.drinks.forEach((drink) => {
              mywindow.document.write(
                "<dd> " +
                  drink.title +
                  " " +
                  this.formatPrice(drink.price) +
                  "</dd>"
              );
            });
          }
          mywindow.document.write("<hr/>");
        });

        mywindow.document.write("</dl>");

        mywindow.document.write("<hr/>");
        if (order.comment) {
          mywindow.document.write(
            "<p>" + this.$t("order.comment") + ": " + order.comment + "</p>"
          );
        }
        mywindow.document.write("<hr/>");

        mywindow.document.write(
          "<b>" +
            ` ${this.$t("cart.total_price")}: ` +
            this.formatPrice(
              parseFloat(this.totalPrice(order.json_items)) +
                (order.order_type == "delivery" ? order.delivery_charge : 0) +
                parseFloat(order.night_shift_charge)
            ) +
            "</b>"
        );

        // mywindow.document.write(document.getElementById("print").innerHTML);
        mywindow.document.write("<br><br>");
        mywindow.document.write("<br><br>");

        mywindow.document.write("</body></html>");

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        // mywindow.close();

        // return true;
      }
    },
    setOrderType(order_type) {
      return order_type == "at_restaurant"
        ? 1
        : order_type == "pick_up"
        ? 2
        : 3;
    },
    // fetch addresses for customer
    async fetchAddresses() {
      this.global_loading.show = true;
      await this.$http
        .get(this.$hostname + "/client/addresses", this.token.config)
        .then((resp) => {
          this.successRequest();

          this.customer_addresses.data = resp.data.addresses;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    createAddress(address) {
      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/client/addresses", address, this.token.config)
        .then((resp) => {
          this.successRequest();
          this.closeModal("edit_address_modal");

          this.fetchAddresses();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    updateAddress(address) {
      this.global_loading.show = true;
      this.$http
        .patch(
          this.$hostname + "/client/addresses/" + address.id,
          address,
          this.token.config
        )
        .then((resp) => {
          this.successRequest();
          this.closeModal("edit_address_modal");
          this.fetchAddresses();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    deleteAddress(id) {
      this.global_loading.show = true;
      this.$http
        .delete(this.$hostname + "/client/addresses/" + id, this.token.config)
        .then((resp) => {
          this.successRequest();
          this.closeModal("delete_address_modal");
          this.fetchAddresses();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchDrinks() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/menu/drinks?qr=" + this.$store.state.qr)
        .then((resp) => {
          this.successRequest();
          this.customer_drinks.data = resp.data.drinks;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchOrdersInProgress() {
      this.global_loading.show = true;
      this.customer_orders.data = [];
      this.$http
        .get(
          this.$hostname +
            (localStorage.token
              ? "/client/orders"
              : "/fetch-orders?orders=" + localStorage.orders),
          this.token.config
        )
        .then((resp) => {
          this.successRequest();

          if (!localStorage.token) {
            resp.data.orders.forEach((order) => {
              this.customer_orders.data.push(order);
            });
          } else {
            this.customer_orders.data = resp.data.orders;
          }

          if (resp.data.orders.length && resp.data.orders.length > 0) {
            this.customer_orders.has_in_progress = true;
          } else {
            this.customer_orders.has_in_progress = false;
          }
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchOrdersHistory() {
      this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname +
            (localStorage.token
              ? "/client/histories"
              : "/local-histories?orders=" + localStorage.orders),
          this.token.config
        )
        .then((resp) => {
          this.successRequest();
          this.customer_orders.data = resp.data.histories;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    deliveryFeeFormatter(restaurant) {
      if (restaurant.fixed_delivery == 1) {
        return this.formatPrice(restaurant.delivery_charge);
      }
      return this.formatPrice(restaurant.delivery_fee_ratio) + "/km";
    },
    setRedirectPath(path) {
      localStorage.redirect_to = path;
    },
    logout() {
      // this.$router.push('/')

      let data = {};
      this.$http
        .post(this.$hostname + "/client/logout", data, this.token.config)
        .then((resp) => {
          window.location.reload(true);

          // this.$router.push('/')
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    clearStorage() {
      localStorage.removeItem("total");
      localStorage.removeItem("comment");
      localStorage.removeItem("table");
      localStorage.removeItem("cart");
      localStorage.removeItem("qr");
      localStorage.removeItem("address_id");
      localStorage.removeItem("payment_type");
      localStorage.removeItem("order_type");
      localStorage.removeItem("night_shift_charge")
      localStorage.removeItem("delivery_charge")
      localStorage.removeItem("preferred_receive_at")
      localStorage.removeItem("min_order_charge")
    },
    savePhoneNumber() {
      if (localStorage.token) {
        this.$http
          .get(this.$hostname + "/client/profile", this.token.config)
          .then((resp) => {
            localStorage.phone_number = resp.data.information[0].phone_number;
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
    successRequest() {
      this.global_loading.show = false;
    },
    showErrors(errors) {
      console.log(errors);
      this.global_loading.show = false;
      if (
        errors.response.status == 401 ||
        errors.response.status == 403 ||
        errors.response.status == 405
      ) {
        this.setRedirectPath(this.$route.name);

        if (localStorage.token) {
          localStorage.removeItem("token");
          this.$router.push("/login");
        }

        this.$store.dispatch("login", {
          auth: false,
        });

        localStorage.removeItem("auth");
      }
      if (errors.response.data.errors) {
        // this.global_error.message = errors.response.data.errors[0]
        this.global_error.message = JSON.parse(
          JSON.stringify(errors.response.data.errors[0])
        );
      }
      if (errors.response.data.message) {
        if (errors.response.status == 401) {
          this.global_error.message = "User not logged in!";
        } else {
          this.global_error.message = JSON.parse(
            JSON.stringify(errors.response.data.message)
          );
        }
      }
      if (errors.response.data.error) {
        this.global_error.message = JSON.parse(
          JSON.stringify(errors.response.data.error)
        );
      }

      this.global_error.show = true;
    },
  },
});
