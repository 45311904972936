<template>
  <div class="checkout">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/basket'"></back-button>
        <div class="title">{{ $t("order.order_type") }}</div>
        <div class="actions">
          <call-waiter></call-waiter>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="btn">
        <h4>
          <div :class="[type != 0 ? 'complete' : '', 'step']"></div>

          {{ $t("cart.how_want_order") }}
        </h4>
        <div class="options">
          <div
            @click="changeType(1)"
            v-if="make_order == 1"
            :class="[type == 1 ? 'active_option' : '', 'option']"
          >
            <label for="restaurant">{{ $t("cart.have_at_restaurant") }}</label>
            <input type="radio" name="type" id="restaurant" />
          </div>

          <div
            v-if="pick_up == 1"
            @click="changeType(2)"
            :class="[type == 2 ? 'active_option' : '', 'option']"
          >
            <label for="pick">{{ $t("cart.pick_up") }}</label>
            <input type="radio" name="type" id="pick" />
          </div>
          <div
            @click="changeType(3)"
            v-if="delivery == 1"
            :class="[type == 3 ? 'active_option' : '', 'option']"
          >
            <label for="deliver">{{ $t("menu.delivery") }}</label>
            <input type="radio" name="type" id="deliver" />
          </div>
        </div>
        <div v-if="type == 1" class="warning">
          {{ "*" + $t("order.warning_inside") }}
        </div>
        <div v-if="type == 3 && isLessThanMinAmount" class="delivery">
          <h4 class="note">
            {{ $t("cart.less_than_min_amount") + ":" }}
          </h4>
        </div>

        <div v-if="type == 3 && isLessThanMinAmount" class="delivery note">
          <div>
            {{ $t("cart.min_order_amount") + ":" }}
          </div>
          <div>
            {{ formatPrice(min_amount_order_delivery) }}
          </div>
        </div>
      </div>

      <div v-if="type == 1" class="btn">
        <div class="table">
          <h4>
            <div :class="[type == 1 && table ? 'complete' : '', 'step']"></div>

            <label for="number-restaurant1234">
              {{ $t("call.table_number") }}
            </label>
          </h4>
          <input
            @input="setTable"
            v-model="table"
            type="text"
            autocomplete="off"
            name="table-number"
            id="table-number"
          />
        </div>
      </div>

      <div v-if="type == 3" class="btn">
        <h4>
          <div
            :class="[
              type == 3 && selected_address.data.id ? 'complete' : '',
              'step',
            ]"
          ></div>

          {{ $t("restaurant.address") + ":" }}
        </h4>

        <div v-if="customer_addresses.data.length" class="addresses">
          <div
            v-for="(address, index) in customer_addresses.data"
            :key="index"
            :class="[
              selected_address.data.id == address.id ? 'active_option' : '',
              'btn',
              'address',
            ]"
            @click="selectAddress(address)"
          >
            <div v-if="address.title" class="head">
              <div class="active-text">{{ address.title }}</div>
              <div class="actions">
                <button
                  @click="showAddEditModal('edit_address_modal', address, true)"
                  class="icon edit"
                ></button>
              </div>
            </div>
            <div class="description">
              <p>
                {{
                  address.description +
                  (address.zip_code ? ", " + address.zip_code : "") +
                  (address.city ? " " + address.city : "")
                }}
              </p>
              <div class="actions" v-if="!address.title">
                <button
                  @click="showAddEditModal('edit_address_modal', address, true)"
                  class="icon edit"
                ></button>
              </div>
            </div>
          </div>
        </div>

        <div v-if="type == 3 && isMoreThanMaxDistance" class="delivery">
          <h4 class="note">
            {{ $t("cart.address_too_far") }}
          </h4>
        </div>

        <button
          class="light"
          @click="showAddEditModal('edit_address_modal', {}, false)"
        >
          {{ $t("cart.add_address") }}
        </button>
      </div>

      <!-- payment type choosing -->
      <div
        :style="[
          activatePayment
            ? {
                opacity: '1',
              }
            : {
                opacity: '0.2',
              },
        ]"
        class="btn"
      >
        <h4>
          <div
            :class="[
              (type == 1 && table) ||
              type == 2 ||
              (type == 3 && selected_address.data.id)
                ? ''
                : 'not-selected',
              'step',
              ((type == 1 || type == 2) && payment == 1) ||
              payment == 2 ||
              (type == 3 && payment == 3)
                ? 'complete'
                : 'step',
            ]"
          ></div>

          {{ $t("cart.what_payment_method") }}
        </h4>
        <div class="options">
          <div
            v-if="
              (cash_dinein == 1 && type == 1) || (type == 2 && cash_pickup == 1)
            "
            @click="changePayment(1)"
            :class="[
              payment == 1 ? 'active_option' : '',
              'option',
              type == 3 ? 'deactive_option' : '',
            ]"
          >
            <label for="cash">{{ $t("cart.cash_card") }}</label>
            <input type="radio" name="payment" id="cash" />
          </div>
          <div
            v-if="
              (online_dinein == 1 && type == 1) ||
              (type == 2 && online_pickup == 1) ||
              (type == 3 && online_delivery == 1)
            "
            @click="changePayment(2)"
            :class="[payment == 2 ? 'active_option' : '', 'option']"
          >
            <label for="online">{{ $t("cart.online_payment") }}</label>
            <input type="radio" name="payment" id="online" />
          </div>
          <div
            v-if="cash_delivery == 1 && type == 3"
            @click="changePayment(3)"
            :class="[
              payment == 3 ? 'active_option' : '',
              'option',
              type != 3 ? 'deactive_option' : '',
            ]"
          >
            <label for="place">{{ $t("cart.at_place") }}</label>
            <input type="radio" name="payment" id="place" />
          </div>
        </div>

        <div v-if="type == 2" class="email">
          <div class="input">
            <div :class="[name ? 'complete' : '', 'step']"></div>

            <label class="deactive" for="name">
              {{ $t("information.name") + ":" }}
            </label>
            <input
              class="active"
              type="text"
              id="name"
              name="name"
              @input="setLocalName"
              v-model="name"
              @change="saveUser"
            />
          </div>
        </div>
        <div v-if="type == 2" class="email">
          <div class="input">
            <div :class="[phone_number ? 'complete' : '', 'step']"></div>

            <label class="deactive" for="phone_number">
              {{ $t("information.phone_number") + ":" }}
            </label>
            <input
              class="active"
              type="text"
              id="phone_number"
              name="phone_number"
              @input="setLocalPhoneNumber"
              v-model="phone_number"
              @change="saveUser"
            />
          </div>
        </div>

        <div v-if="type == 1 && payment == 2 && !hasToken" class="email">
          <div class="note">{{ $t("cart.warning_email") }}</div>
          <div class="input">
            <div :class="[local_email ? 'complete' : '', 'step']"></div>

            <label class="deactive" for="email">
              {{ $t("registration.email") + ":" }}
            </label>
            <input
              class="active"
              type="email"
              id="email"
              name="email"
              @input="setLocalEmail"
              v-model="local_email"
              @change="emailValidation"
            />
          </div>

          <div v-if="email_invalid" class="note">
            *{{ $t("registration.email_error") }}!
          </div>
          <div v-if="msg_email_invalid.length" class="note">
            *{{ msg_email_invalid }}!
          </div>
        </div>
      </div>

      <div
        v-if="
          (type == 1 && pre_ordering_option_dinein == 1) ||
          (type == 2 && pre_ordering_option_pickup == 1) ||
          (type == 3 && pre_ordering_option_delivery == 1)
        "
        class="btn pre-order"
      >
        <div class="switch">
          <label for="pre_ordering_option">{{
            $t("order.pre_ordering")
          }}</label>

          <v-app>
            <v-switch
              @change="switchPreOrder"
              color="#ff6a4d"
              v-model="pre_order_option"
              :flat="true"
              inset
            ></v-switch>
          </v-app>
        </div>
        <div v-if="pre_order_option == 1" class="dropdown">
          <label for="selected_pre_day">{{ $t("history.date") }}</label>
          <select
            v-model="selected_pre_day"
            @change="fetchPreferredTimes()"
            name="day"
            id="day"
          >
            <option :value="date" :key="date" v-for="(date, day) in days">
              {{ day + " " + formatDate(date) }}
            </option>
          </select>
        </div>
        <div v-if="pre_order_option == 1" class="dropdown">
          <label for="selected_pre_time">{{ $t("history.time") }}</label>
          <select
            @change="savePreferredTime()"
            v-model="selected_pre_time"
            name="day"
            id="day"
          >
            <option
              :value="time"
              :key="time"
              v-for="(time, index) in preferred_times"
            >
              {{ index }}
            </option>
          </select>
        </div>
      </div>

      <div v-if="hasToken" class="btn">
        <div class="promotion">
          <h4>
            <div
              :class="[is_valid_promo_code ? 'complete' : 'incomplete', 'step']"
            >
              {{ is_valid_promo_code ? "" : "-" }}
            </div>

            <label for="promotion_code">
              {{ $t("cart.discount_code") }}
            </label>
          </h4>
          <input
            @change="checkValidPromoCode"
            v-model="promotion_code"
            name="promotion_code"
            id="promotion_code"
          />
        </div>
        <div v-if="is_valid_promo_code" class="promotion">
          <div class="price">
            {{ "Discount of " + discount + "% was added to your basket." }}
          </div>
        </div>
      </div>

      <div v-if="payment == 2" class="visma">
        <button @click="showModal('payment_terms_modal')">
          {{ $t("cart.visma_conditions") }}
        </button>
      </div>
      <input v-model="hidden_input" name="hidden_input" id="hidden_input" />
    </div>

    <continue :completed_order="completedOrder"></continue>
    <div id="blur_background" class="blur-bg">
      <edit-address-modal
        :edit="edit"
        :address="selected_address.data"
      ></edit-address-modal>
      <call-waiter-modal></call-waiter-modal>
      <succeed-modal :message="global_success.message"></succeed-modal>
      <login-modal></login-modal>
      <signup-modal></signup-modal>
      <otp-verification-modal></otp-verification-modal>
      <payment-terms-modal></payment-terms-modal>
      <forgot-password-modal></forgot-password-modal>
      <privacy-modal></privacy-modal>
    </div>

    <div class="prices">
      <div class="night_shift">
        <div>
          {{ $t("cart.subtotal") }}
        </div>
        <div>
          {{
            formatPrice(totalInBasket() - (totalInBasket() * discount) / 100)
          }}
        </div>
      </div>

      <div v-if="type == 3 && !isMoreThanMaxDistance" class="delivery">
        <h4
          :style="[
            isTotalMoreThanMin || delivery_charge == 0
              ? {
                  color: '#FF6A4D',
                }
              : {
                  color: '#4d4d4d',
                },
          ]"
        >
          {{ $t("order.delivery_fee") + ":" }}
        </h4>

        <div
          class="price"
          :style="[
            isTotalMoreThanMin || delivery_charge == 0
              ? {
                  color: '#FF6A4D',
                }
              : {
                  color: '#4d4d4d',
                },
          ]"
        >
          {{
            isTotalMoreThanMin || delivery_charge == 0
              ? $t("cart.free")
              : formatPrice(
                  delivery_charge - (delivery_charge * discount) / 100
                )
          }}
        </div>
      </div>
      <div v-if="night_shift_charge > 0 && type == 3" class="night_shift">
        <div>{{ $t("order.night_shift") + ":" }}</div>
        <div>
          {{ formatPrice(night_shift_charge) }}
        </div>
      </div>
      <div v-if="min_order_charge > 0 && type == 3" class="night_shift">
        <div>{{ "Pienen tilauksen maksu" + ":" }}</div>
        <div>
          {{ formatPrice(min_order_charge) }}
        </div>
      </div>
      <div class="discount">
        <div>
          {{ $t("cart.discount") }}
        </div>
        <div class="price">
          {{
            "-" +
            formatPrice(((totalInBasket() + delivery_charge) * discount) / 100)
          }}
        </div>
      </div>
      <div class="total">
        <div>
          {{ $t("cart.total_price") + " " + $t("cart.incl_vat") }}
        </div>
        <div>{{ $store.state.total }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import BackButton from "../components/common/BackButton.vue";
import CallWaiter from "../components/customer/CallWaiter.vue";
import Continue from "../components/customer/Continue.vue";
import EditAddressModal from "../components/customer/EditAddressModal.vue";
import CallWaiterModal from "../components/customer/CallWaiterModal.vue";
import SucceedModal from "../components/customer/SucceedModal.vue";
import LoginModal from "../components/customer/LoginModal.vue";
import SignupModal from "../components/customer/SignupModal.vue";
import OtpVerificationModal from "../components/customer/OtpVerificationModal.vue";
import PaymentTermsModal from "../components/customer/PaymentTermsModal.vue";
import ForgotPasswordModal from "../components/customer/ForgotPasswordModal.vue";
import PrivacyModal from "../components/common/PrivacyModal.vue";

export default {
  mounted() {
    this.setAddress({});
    if (this.$route.query.basket_id) {
      this.fetchBasket(this.$route.query.basket_id);
    } else {
      this.checkEmptyCart();
      this.fetchRestaurant();
    }
    if (localStorage.token) {
      this.fetchUser();
      this.fetchAddresses();
    }
  },
  updated() {
    this.totalInBasket();

    this.completedOrder;

    if (this.discount > 0) {
      this.setDiscount(this.discount);
    }
  },
  computed: {
    completedOrder() {
      if (this.type == 2 && !this.phone_number) {
        return false;
      }

      if (this.selected_address.data.id && this.type == 3) {
        if (this.cash_delivery == 1 && this.online_delivery == 0) {
          this.changePayment(3);
        } else if (this.online_delivery == 1 && this.cash_delivery == 0) {
          this.changePayment(2);
        }
      }

      if (this.type != 0 && this.payment != 0) {
        if (this.type == 1 && !this.table.length) {
          return false;
        }

        if (
          this.type == 1 &&
          !this.local_email.length &&
          this.payment == 2 &&
          !localStorage.token
        ) {
          return false;
        }

        if (this.type == 3) {
          this.isLessThanMinAmount;
          if (this.isMoreThanMaxDistance) {
            return false;
          }
          if (this.selected_address.data.id) {
            if (this.payment == 1) {
              return false;
            }

            return true;
          }
          return false;
        }

        if (
          (this.type == 2 && this.payment == 3) ||
          (this.type == 1 && this.payment == 3)
        ) {
          return false;
        }
        return true;
      }
      return false;
    },
    activatePayment() {
      if (this.type == 1) {
        if (this.table.length) {
          return true;
        }
        return false;
      } else if (this.type == 2) {
        return true;
      } else if (this.type == 3) {
        if (this.selected_address.data.id) {
          return true;
        }
        return false;
      }
      return false;
    },
    hasToken() {
      return this.$store.state.auth;
    },
    isTotalMoreThanMin() {
      return (
        parseFloat(this.totalInBasket()) >=
          parseFloat(this.minimum_order_free_delivery) &&
        this.free_delivery_option == 1
      );
    },
    isMoreThanMaxDistance() {
      if (this.max_distance_order_delivery > 0) {
        return (
          parseFloat(this.distance) >=
          parseFloat(this.max_distance_order_delivery)
        );
      }
      return false;
    },
    isLessThanMinAmount() {
      if (
        parseFloat(this.totalInBasket()) <
        parseFloat(this.min_amount_order_delivery)
      ) {
        this.setMinOrderCharge(
          this.min_amount_order_delivery - this.totalInBasket()
        );
      } else {
        this.setMinOrderCharge(0);
      }
      // return (
      //   parseFloat(this.totalInBasket()) <
      //   parseFloat(this.min_amount_order_delivery)
      // );
    },
  },
  methods: {
    fetchRestaurant() {
      this.global_loading.show = true;
      this.$http
        .get(
          this.$hostname + "/fetch-restaurant/" + localStorage.menu,
          this.token.config
        )
        .then((resp) => {
          this.restaurant_id = resp.data.restaurant[0].id;

          this.days = resp.data.preferred_days;

          if (
            resp.data.restaurant[0].end_night_shift &&
            resp.data.restaurant[0].start_night_shift
          ) {
            this.night_shift_charge = this.setNightShiftCharge(
              resp.data.restaurant[0].start_night_shift,
              resp.data.restaurant[0].end_night_shift,
              resp.data.restaurant[0].night_shift_charge
            );
          }

          localStorage.night_shift_charge = this.night_shift_charge;

          this.online_delivery = resp.data.restaurant[0].online_delivery;

          this.online_dinein = resp.data.restaurant[0].online_dinein;

          this.online_pickup = resp.data.restaurant[0].online_pickup;

          this.cash_delivery = resp.data.restaurant[0].cash_delivery;

          this.cash_dinein = resp.data.restaurant[0].cash_dinein;

          this.cash_pickup = resp.data.restaurant[0].cash_pickup;

          this.pre_ordering_option_delivery =
            resp.data.restaurant[0].pre_ordering_option_delivery;
          this.pre_ordering_option_pickup =
            resp.data.restaurant[0].pre_ordering_option_pickup;
          this.pre_ordering_option_dinein =
            resp.data.restaurant[0].pre_ordering_option_dinein;

          this.min_amount_order_delivery =
            resp.data.restaurant[0].min_amount_order_delivery;

          this.max_distance_order_delivery =
            resp.data.restaurant[0].max_distance_order_delivery;

          this.pick_up = parseInt(resp.data.restaurant[0].pick_up);

          this.delivery = parseInt(resp.data.restaurant[0].delivery);

          this.make_order = parseInt(resp.data.restaurant[0].make_order);

          this.minimum_order_free_delivery = parseFloat(
            resp.data.restaurant[0].minimum_order_free_delivery
          ).toFixed(2);

          this.free_delivery_option =
            resp.data.restaurant[0].free_delivery_option;

          this.exception_delivery_fee_option =
            resp.data.restaurant[0].exception_delivery_fee_option;

          localStorage.minimum_order_free_delivery = parseFloat(
            resp.data.restaurant[0].minimum_order_free_delivery
          ).toFixed(2);

          localStorage.free_delivery_option =
            resp.data.restaurant[0].free_delivery_option;

          if (localStorage.discount) {
            this.promotion_code = localStorage.discount;
            if (this.promotion_code == localStorage.discount) {
              this.checkValidPromoCode();
            }
          }
          // localStorage.delivery_charge =
          //   resp.data.restaurant[0].delivery_charge;
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    fetchPreferredTimes() {
      this.global_loading.show = true;
      this.preferred_times = [];
      this.$http
        .get(
          this.$hostname +
            "/fetch-workinghours/" +
            this.restaurant_id +
            "?day=" +
            this.selected_pre_day
        )
        .then((resp) => {
          this.preferred_times = resp.data.preferred_times;
          this.successRequest();
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    savePreferredTime() {
      localStorage.preferred_receive_at =
        this.selected_pre_day + " " + this.selected_pre_time;
    },
    switchPreOrder() {
      localStorage.removeItem("preferred_receive_at");
      this.selected_pre_day = "";
      this.selected_pre_time = "";
    },
    fetchUser() {
      this.global_loading.show = true;
      this.$http
        .get(this.$hostname + "/client/profile", this.token.config)
        .then((resp) => {
          this.successRequest();
          this.name = resp.data.information[0].name;
          this.phone_number = resp.data.information[0].phone_number;
          this.email = resp.data.information[0].email;
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
    saveUser() {
      if (this.name && this.email && this.phone_number) {
        let data = {
          name: this.name,
          email: this.email,
          phone_number: this.phone_number,
        };
        // this.global_loading.show = true;
        this.$http
          .patch(this.$hostname + "/client/profile", data, this.token.config)
          .then((resp) => {
            // this.successRequest();
            this.person.name = resp.data.name;
            this.person.email = resp.data.email;
            this.person.phone_number = resp.data.phone_number;
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
    checkEmptyCart() {
      if (this.$store.state.cart.length == 0) {
        this.$router.push("/");
      }
      return;
    },
    changeType(val) {
      this.nightShiftRetrive(val);
      this.isLessThanMinAmount;

      if (val == 1) {
        this.delivery_charge = 0;
        this.setDeliveryCharge(0);
        this.setMinOrderCharge(0);

        this.type = val;
        localStorage.order_type = val;
      } else {
        // this.changePayment(0);

        // open login modal
        if (
          (val == 2 && !localStorage.token) ||
          (val == 3 && !localStorage.token)
        ) {
          this.openSignupModal(this.$route.name);
        }

        if (val == 3 && localStorage.token) {
          if (this.online_delivery == 1) {
            this.changePayment(2);
          }
          if (this.cash_delivery == 1 && this.online_delivery == 0) {
            this.changePayment(3);
          }

          this.fetchAddresses();

          if (this.customer_addresses.data[0]) {
            this.selectAddress(this.customer_addresses.data[0]);
          }

          this.type = val;
          localStorage.order_type = val;
          this.isLessThanMinAmount;
        }
        // maybe here will make bugs
        //  else {
        //   this.setDeliveryCharge(0);

        //   this.delivery_charge = 0;
        // }

        if (val == 2 && localStorage.token) {
          this.setDeliveryCharge(0);
          this.setMinOrderCharge(0);

          this.type = val;
          localStorage.order_type = val;

          if (this.cash_pickup == 1) {
            this.changePayment(1);
          }
          if (this.cash_pickup == 0 && this.online_pickup == 1) {
            this.changePayment(2);
          }
        }
      }
    },
    changePayment(pay) {
      console.log(pay, "payment type");
      if (this.activatePayment) {
        this.payment = pay;
        localStorage.payment_type = pay;
      } else {
        this.payment = 0;
        localStorage.payment_type = 0;
      }
    },
    showAddEditModal(modal_id, address, edit) {
      this.showModal(modal_id);
      if (!edit) {
        this.savePhoneNumber();
      }
      this.edit = edit;

      // this.$router.replace(`/checkout?address=true`).catch(() => {});

      // this.selectAddress(address);
    },
    selectAddress(address) {
      this.setAddress(address);
      this.calcDistance(address.id, this.restaurant_id);

      if (this.cash_delivery == 1 && this.online_delivery == 0) {
        this.changePayment(3);
      } else if (this.online_delivery == 1 && this.cash_delivery == 0) {
        this.changePayment(2);
      }
    },
    setAddress(address) {
      this.selected_address.data = Object.assign({}, address);

      localStorage.setItem("address_id", this.selected_address.data.id);
    },
    setTable() {
      localStorage.table = this.table;
      if (this.cash_dinein == 1 && this.online_dinein == 0) {
        this.changePayment(1);
      } else if (this.online_dinein == 1 && this.cash_dinein == 0) {
        this.changePayment(2);
      }
    },
    setLocalEmail() {
      localStorage.local_email = this.local_email;
    },
    setLocalPhoneNumber() {
      localStorage.local_phone_number = this.phone_number;
    },
    setLocalName() {
      localStorage.local_name = this.name;
    },
    emailValidation() {
      this.validateEmailFormat();
      if (this.local_email) {
        this.email_invalid = false;
      } else {
        this.email_invalid = true;
      }
    },
    validateEmailFormat() {
      if (
        !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.local_email)
      ) {
        this.msg_email_invalid = "Please enter a valid email address";
      } else {
        this.msg_email_invalid = "";
      }
    },
    calcDistance(address_id, restaurant_id) {
      if (address_id) {
        this.$http
          .get(
            this.$hostname +
              "/client/calc-distance?address_id=" +
              address_id +
              "&restaurant_id=" +
              restaurant_id +
              "&total=" +
              this.totalInBasket(),
            this.token.config
          )
          .then((resp) => {
            console.log(resp.data.distance);
            if (resp.data.delivery_fee != null) {
              console.log(resp.data.delivery_fee, "estimated");
              this.setDeliveryCharge(resp.data.delivery_fee);
            } else {
              console.log(resp.data.delivery_fee, "it is out of the range");
            }
            if (this.discount > 0) {
              this.setDiscount(this.discount);
            }
            this.distance = resp.data.distance / 1000;
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
    checkValidPromoCode() {
      if (this.hasToken && this.promotion_code) {
        this.$http
          .get(
            this.$hostname +
              "/client/promotion-codes/" +
              this.promotion_code +
              "?restaurant_id=" +
              this.restaurant_id,
            this.token.config
          )
          .then((resp) => {
            localStorage.discount = resp.data.promotion_code.code;
            this.is_valid_promo_code =
              resp.data.promotion_code.code == this.promotion_code;
            this.discount = resp.data.promotion_code.discount;
            this.setDiscount(this.discount);
          })
          .catch((err) => {
            this.setDiscount(0);
            this.discount = 0;

            localStorage.removeItem("discount");

            this.is_valid_promo_code = false;
            this.showErrors(err);
          });
      } else {
        this.discount = 0;
        this.setDiscount(this.discount);
        localStorage.removeItem("discount");
        this.is_valid_promo_code = false;
      }
    },
    setDeliveryCharge(delivery_fee) {
      localStorage.delivery_charge = delivery_fee;

      this.delivery_charge = localStorage.delivery_charge;

      this.$store.dispatch("addDeliveryCharge", {
        delivery_charge: delivery_fee,
      });
    },
    nightShiftRetrive(order_type) {
      localStorage.night_shift_charge =
        order_type == 3 ? this.night_shift_charge : 0;
    },
    setMinOrderCharge(min_order_charge) {
      console.log("min order charge" + min_order_charge);
      if (this.type == 3) {
        localStorage.min_order_charge = min_order_charge;
        this.min_order_charge = min_order_charge;
      } else {
        localStorage.min_order_charge = 0;
        this.min_order_charge = 0;
      }
    },
    setDiscount(discount) {
      this.$store.dispatch("addDiscountToCart", {
        discount: discount,
      });
    },
    fetchBasket(basket_id) {
      this.$http
        .get(this.$hostname + "/baskets/" + basket_id)
        .then((resp) => {
          localStorage.menu = resp.data.basket[0].menu;
          localStorage.qr = resp.data.basket[0].menu;

          this.$store.dispatch("createNewCart", {
            basket: resp.data.basket[0].json_items,
          });

          localStorage.comment = resp.data.basket[0].comment;

          this.checkEmptyCart();
          this.fetchRestaurant();
          if (localStorage.token) {
            this.fetchAddresses();
          }
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
  data() {
    return {
      type: 0,
      payment: 2,
      edit: false,
      new_address: {},
      table: "",
      local_email: "",
      email_invalid: false,
      msg_email_invalid: "",
      pick_up: 0,
      delivery: 0,
      make_order: 0,
      delivery_charge: 0,
      minimum_order_free_delivery: 0,
      online_dinein: 0,
      cash_dinein: 0,
      online_pickup: 0,
      cash_pickup: 0,
      online_delivery: 0,
      cash_delivery: 0,
      night_shift_charge: 0,
      min_order_charge: 0,
      distance: 0,
      restaurant_id: 0,
      max_distance_order_delivery: 0,
      min_amount_order_delivery: 0,
      free_delivery_option: 0,
      promotion_code: "",
      discount: 0,
      is_valid_promo_code: false,
      hidden_input: "",
      name: "",
      phone_number: "",
      email: "",
      exception_delivery_fee_option: 0,
      days: [],
      selected_pre_day: "",
      preferred_times: [],
      selected_pre_time: "",
      pre_ordering_option_delivery: 0,
      pre_ordering_option_pickup: 0,
      pre_ordering_option_dinein: 0,
      pre_order_option: 0,
    };
  },
  components: {
    BackButton,
    Continue,
    EditAddressModal,
    CallWaiter,
    CallWaiterModal,
    SucceedModal,
    LoginModal,
    SignupModal,
    OtpVerificationModal,
    PaymentTermsModal,
    ForgotPasswordModal,
    PrivacyModal,
  },
};
</script>
<style scoped>
.checkout {
  overflow: hidden;
  max-height: 100%;
}

.container {
  justify-content: space-between;
}

.content {
  padding: 72px 16px 220px;
  overflow: hidden;
  gap: 4px;
  height: 100%;
  justify-content: space-between;
}

.prices {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  padding: 8px 16px;
  bottom: 114px;
  background-color: #ffffff;
  max-width: 768px;
  left: 50%;
  transform: translate(-50%, 0);
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.btn {
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 16px;
  padding: 8px 0;
  background-color: transparent;
}

input[type="radio"] {
  appearance: none;
}
.table input,
input[type="email"] {
  background-color: #fff7f5;
  border-radius: 8px;
  height: 32px;
  width: 80px;
  text-align: center;
}

.email input {
  background-color: #fff7f5;
  border-radius: 8px;
  height: 32px;
  width: 100%;
  padding: 0 8px;
}
.email .input {
  background-color: #ffffff;
  padding: 4px 0px;
}

.email .input label {
  border-right: 1px solid #ffffff;
  color: #4d4d4d;
  padding: 0;
}

.email .note {
  padding: 0;
  margin: 8px 0;
  width: 100%;
}

.options {
  width: 100%;
  display: flex;
  gap: 8px;
}

.option {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #8f93bf3d;
  width: 100%;
  height: 40px;
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  cursor: pointer;
  color: #4d4d4d;
}
.option label {
  width: 100%;
  cursor: pointer;
  text-align: center;
}
.active_option label {
  cursor: default;
}

h4 {
  display: flex;
  align-items: center;
  gap: 4px;
}

.table,
.email {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
}
.promotion {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 8px;
}
.promotion input {
  background-color: #fff7f5;
  border-radius: 8px;
  height: 32px;
  width: 50%;
  text-align: center;
}

.email {
  flex-direction: column;
  padding: 0;
}
.add {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  gap: 12px;
  padding-right: 8px;
  width: 100%;
}
.add span {
  font-weight: 300;
  font-size: 12px;
  text-decoration-line: underline;
  color: #ff6a4d;
}
.addresses {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}
.address {
  /* background-color: #f8f9fa; */
  border: 1px solid #cccccc;
  gap: 14px;
  border-radius: 8px;
  cursor: pointer;
  padding: 16px 12px;
}

.active-text {
  font-weight: 400;
  font-size: 16px;
  color: #4d4d4d;
}
.active_option {
  cursor: default;
  border: 1px solid #ff6a4d;
}
.deactive_option {
  opacity: 0.2;
  border: 1px solid #8f93bf3d;
}
.incomplete {
  opacity: 0.5;
}
.delivery {
  display: flex;
  width: 100%;
  color: #4d4d4d;
  justify-content: space-between;
}
.delivery h4 {
  color: #4d4d4d;
}
.price {
  color: #776282;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.night_shift {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-size: 14px;
  color: #4d4d4d;
}

.form {
  padding: 0 12px;
  width: 100%;
}
.form .input {
  background-color: #ffffff;
}

.form textarea {
  width: 100%;
}
.visma {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 4px 0;
  text-decoration: underline;
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  padding-top: 16px;
  color: #737373;
}
.description {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.step {
  border-radius: 50%;
  width: 28px;
  height: 20px;
  background-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/customer/menu/step_active.svg");
  color: #ffffff;
}
.promotion .incomplete {
  background: none;
  color: #4d4d4d;
  width: fit-content;
}
.complete {
  min-width: 20px;
  background-image: url("../assets/customer/menu/step_done.svg");
}

.not-selected {
  background-image: url("../assets/customer/menu/step_deactive.svg");
}

.light {
  width: 100%;
}

.note {
  font-size: 14px !important;
  color: #ff6a4d !important;
}

.warning {
  font-weight: 400;
  font-size: 13px;
  color: #737373;
}

.total {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
}
.discount {
  display: flex;
  width: 100%;
  color: #4d4d4d;
  justify-content: space-between;
  border-top: 1px solid #f5f5f5;
  padding-top: 8px;
  margin-top: 8px;
}

#hidden_input {
  opacity: 0;
  max-height: 0;
  max-width: 0;
}
.pre-order {
  gap: 8px;
}
.pre-order select {
  width: 100%;
  background: #fff7f5;
  border-radius: 8px;
  text-align: center;
  height: 38px;
  color: #4d4d4d;
  font-weight: 300;
  font-size: 14px;
  border: 1px solid #ff6a4d;
}
.switch {
  display: flex;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 16px;
  color: #4d4d4d;
  max-height: 28px;
}

.dropdown label {
  font-weight: 300;
  font-size: 14px;
  color: #4d4d4d;
}
.dropdown {
  width: 100%;
}
</style>
