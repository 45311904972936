<template>
  <div class="navbar" id="navigation">
    <div class="container">
      <router-link to="/">
        <div class="btn">
          <div
            :class="[
              $route.name == 'Home' ? 'active-home' : 'deactive-home',
              'icon',
            ]"
          ></div>
          <div
            :class="[
              $route.name == 'Home' ? 'active-text' : 'deactive-text',
              'caption',
            ]"
          >
            {{ $t("navbar.home") }}
          </div>
        </div>
      </router-link>
      <router-link to="/reservation">
        <div class="btn">
          <div
            :class="[
              $route.name == 'Reservation'
                ? 'active-reservation'
                : 'deactive-reservation',
              'icon',
            ]"
          ></div>
          <div
            :class="[
              $route.name == 'Reservation' ? 'active-text' : 'deactive-text',
              'caption',
            ]"
          >
            {{ $t("navbar.reservation") }}
          </div>
        </div>
      </router-link>
      <router-link to="/history">
        <div class="btn">
          <div
            :class="[
              history_routes.includes($route.name)
                ? 'active-history'
                : 'deactive-history',
              'icon',
            ]"
          >
            <span v-if="customer_orders.has_in_progress" class="badge">•</span>
          </div>
          <div
            :class="[
              history_routes.includes($route.name)
                ? 'active-text'
                : 'deactive-text',
              'caption',
            ]"
          >
            {{ $t("navbar.history") }}
          </div>
        </div>
      </router-link>
      <router-link to="/account">
        <div class="btn">
          <div
            :class="[
              account_routes.includes($route.name)
                ? 'active-account'
                : 'deactive-account',
              'icon',
            ]"
          ></div>
          <div
            :class="[
              account_routes.includes($route.name)
                ? 'active-text'
                : 'deactive-text',
              'caption',
            ]"
          >
            {{ $t("navbar.account") }}
          </div>
        </div>
      </router-link>
    </div>
  </div>
</template>
<script>
import SucceedModal from "../../components/customer/SucceedModal.vue";

export default {
  components: {
    SucceedModal,
  },
  mounted() {
    if (localStorage.token) {
      this.fetchOrdersInProgress();
    }

    Pusher.logToConsole = false;
    const vm = this;

    let channel = this.$pusher.subscribe(
      "rejection-orders-" + this.decodeUserIdByToken()
    );

    channel.bind("rejectionOrder", function (data) {
      if (data.order.status == "rejected") {
        vm.global_error.show = true;
        vm.global_error.message =
          "#" + data.order.id + " " + data.order.reject_reason;
      }
    });
  },
  data() {
    return {
      account_routes: [
        "Account",
        "Profile",
        "Addresses",
        "Favorites",
        "ChangePassword",
      ],
      history_routes: ["History", "Order"],
    };
  },
};
</script>
<style scoped>
.navbar {
  position: fixed;
  bottom: 0%;
  width: 100%;
  z-index: 200;
  background: rgba(245, 246, 247, 0.9);
  backdrop-filter: blur(50px);
}
.container {
  max-width: 768px;
  margin: auto;
  width: 100%;
  padding: 8px 0px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  width: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.btn {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
  width: 100%;
}
.caption {
  font-size: 12px;
  line-height: 14px;
  letter-spacing: -0.3px;
  height: 14px;
}

.active-text {
  color: #ff6a4d;
}

.deactive-text {
  color: #b3b3b3;
}

.deactive-home {
  background-image: url("../../assets/customer/navbar/home.svg");
}
.active-home {
  background-image: url("../../assets/customer/navbar/home_on.svg");
}

.deactive-reservation {
  background-image: url("../../assets/customer/navbar/reservation.svg");
  background-size: 20px;
}
.active-reservation {
  background-image: url("../../assets/customer/navbar/reservation_on.svg");
  background-size: 20px;
}

.deactive-history {
  background-image: url("../../assets/customer/navbar/history.svg");
}
.active-history {
  background-image: url("../../assets/customer/navbar/history_on.svg");
}
.deactive-history,
.active-history {
  position: relative;
}

.deactive-account {
  background-image: url("../../assets/customer/navbar/account.svg");
}
.active-account {
  background-image: url("../../assets/customer/navbar/account_on.svg");
}
</style>
