<template>
  <div id="item_modal" class="modal">
    <div
      class="banner"
      :style="[
        item.photo
          ? {
              'background-image': 'url(' + item.photo + ')',
            }
          : '',
      ]"
    ></div>
    <div class="head">
      <div v-if="item.vegan == 1" class="icon vegan"></div>
      <div v-if="item.gluten == 1" class="icon gluten"></div>
      <div v-if="item.lactose == 1" class="icon lactose"></div>
      <h3>{{ item.title }}</h3>
    </div>
    <div
      v-html="$i18n.locale == 'en' ? item.description_en : item.description_fi"
      class="ingredients"
    ></div>
    <div
      v-if="
        item.energy > 0 ||
        item.protein > 0 ||
        item.fat > 0 ||
        item.carbohydrate > 0 ||
        item.fiber > 0 ||
        item.salt > 0
      "
      class="cap"
    >
      {{ $t("menu.per_portion") }}
    </div>
    <div class="row">
      <!-- Energy -->
      <div v-if="item.energy > 0" class="input">
        <label class="deactive" for="energy">{{
          $t("restaurant_menu.energy")
        }}</label>
        <input
          readonly
          class="active"
          type="text"
          id="energy"
          v-model="item.energy"
        />
        <span>Kcal</span>
      </div>

      <!-- Protein -->
      <div v-if="item.protein > 0" class="input">
        <label class="deactive" for="protein">{{
          $t("restaurant_menu.protein")
        }}</label>
        <input
          readonly
          class="active"
          type="text"
          id="protein"
          v-model="item.protein"
        />
        <span>g</span>
      </div>
    </div>

    <div class="row">
      <!-- Fat -->
      <div v-if="item.fat > 0" class="input">
        <label class="deactive" for="fat">{{
          $t("restaurant_menu.fat")
        }}</label>
        <input
          readonly
          class="active"
          type="text"
          id="fat"
          v-model="item.fat"
        />
        <span>g</span>
      </div>

      <!-- Carbs -->
      <div v-if="item.carbohydrate > 0" class="input">
        <label class="deactive" for="carbs">{{
          $t("restaurant_menu.carbohyd")
        }}</label>
        <input
          readonly
          class="active"
          type="text"
          id="carbs"
          v-model="item.carbohydrate"
        />
        <span>g</span>
      </div>
    </div>

    <div class="row">
      <!-- Fiber -->
      <div v-if="item.fiber > 0" class="input">
        <label class="deactive" for="fiber">{{
          $t("restaurant_menu.fiber")
        }}</label>
        <input
          readonly
          class="active"
          type="text"
          id="fiber"
          v-model="item.fiber"
        />
        <span>g</span>
      </div>

      <!-- Salt -->
      <div v-if="item.salt > 0" class="input">
        <label class="deactive" for="salt">{{
          $t("restaurant_menu.salt")
        }}</label>
        <input
          readonly
          class="active"
          type="text"
          id="salt"
          v-model="item.salt"
        />
        <span>g</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {},
  },
};
</script>
<style scoped>
.modal {
  min-height: 425px;
  background-color: #ffffff;
  height: fit-content;
  max-height: 425px;
  overflow-y: scroll;
  gap: 16px;
  justify-content: flex-start;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-width: 768px;
  margin: 0 auto;
  padding: 12px 12px 28px;
}

.banner {
  width: 100%;
  min-height: 192px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/customer/menu/item.png");
  display: flex;
  padding: 8px;
  justify-content: space-between;
  align-items: flex-end;
}

.head {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
}

.ingredients {
  font-weight: 300;
  font-size: 14px;
  color: #b3b3b3;
}

.icon {
  width: 24px;
}

.vegan {
  background-image: url("../../assets/customer/menu/vegan.svg");
}
.gluten {
  background-image: url("../../assets/customer/menu/gluten.svg");
}
.lactose {
  background-image: url("../../assets/customer/menu/lactose.svg");
}
.row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  width: 100%;
}

.row .input {
  width: 49%;
}

.row input {
  width: 49%;
}
</style>