<template>
  <div id="extras_modal" class="modal">
    <div class="extras">
      <div class="fixed">
        <div
          v-if="item.photo"
          class="banner"
          :style="{
            'background-image': 'url(' + item.photo + ')',
          }"
        >
          <button @click="closeClearModal()" class="icon close"></button>
        </div>
        <div class="head">
          <div class="title">
            <button
              v-if="!item.photo"
              @click="closeClearModal()"
              class="icon close"
            ></button>
            <div v-if="item.discount > 0" class="discount">
              {{ "-" + item.discount + "%" }}
            </div>
            <div v-if="item.vegan == 1" class="icon vegan"></div>
            <div v-if="item.gluten == 1" class="icon gluten"></div>
            <div v-if="item.lactose == 1" class="icon lactose"></div>
            <h4>{{ item.title }}</h4>
          </div>

          <div class="price" v-if="item.discount > 0">
            <s>{{ formatPrice(item.price) }}</s>
            &nbsp;
            {{ formatPrice(item.price - (item.price * item.discount) / 100) }}
          </div>
          <div class="price" v-else>
            {{ formatPrice(item.price) }}
          </div>
        </div>
        <div
          v-if="item.description_en || item.description_fi"
          v-html="
            $i18n.locale == 'en' ? item.description_en : item.description_fi
          "
          class="ingredients"
        ></div>
      </div>
      <div id="scroll_" class="scroll">
        <div class="compulsories">
          <div
            v-for="(compulsory, index) in item.compulsories"
            :key="index"
            class="compulsory"
          >
            <div class="compulsory-title">
              <h4>
                <!-- <div class="icon tip"></div> -->

                {{ compulsory.title }}
                &nbsp;
                <span>{{
                  "(" +
                  $t("cart.compulsory.p1") +
                  compulsory.limit +
                  (compulsory.limit == 1
                    ? $t("cart.compulsory.p2")
                    : $t("cart.compulsory.p3")) +
                  "*)"
                }}</span>
              </h4>
              <div class="required">{{ $t("menu.required") }}</div>
            </div>
            <div class="sub-compulsories" :id="'compulsories-' + index">
              <div
                @click.prevent="addCompulsoryToCart(compulsory, sub_compulsory)"
                v-for="(sub_compulsory, index) in compulsory.sub_compulsories"
                :key="index"
                :class="[
                  'sub-compulsory',
                  added_sub_compulsories.includes(sub_compulsory.id)
                    ? 'active_sub_compulsory'
                    : compulsoryIsFull(compulsory)
                    ? 'off_compulsory'
                    : '',
                ]"
              >
                <div class="option">
                  <label :for="'sub_compulsory_' + sub_compulsory.id">
                    {{ sub_compulsory.title }}</label
                  >
                  <input
                    type="radio"
                    :name="'compulsory_' + compulsory.id"
                    :id="'sub_compulsory_' + sub_compulsory.id"
                  />
                </div>
                <div class="price">
                  {{ "+ " + formatPrice(sub_compulsory.price) }}
                </div>
              </div>
            </div>
            <div
              v-if="compulsory.sub_compulsories.length > 3"
              @click="
                expandCompulsory(index, compulsory.sub_compulsories.length)
              "
              class="show"
            >
              <div class="arrow icon" :id="'arrowmore-' + index"></div>
              <div class="text" :id="'showmore-' + index">
                {{ $t("menu.show_all") }}
              </div>
            </div>
          </div>
        </div>
        <!-- <h4 class="extra-title"></h4> -->
        <div class="extra-title">
          <h4 v-if="item.extras && item.extras.length">
            <!-- <div class="icon tip"></div> -->

            {{ $t("cart.extras") }}
          </h4>
          <div class="optional" v-if="item.extras && item.extras.length">
            {{ $t("menu.optional") }}
          </div>
        </div>

        <div
          v-for="(extra, index) in item.extras"
          :key="index"
          class="extra"
          v-if="extra.status == 1"
          @click="addRemoveExtra(extra)"
        >
          <div class="control">
            <button
              v-if="added_extra_ids.includes(extra.id)"
              class="icon minus"
            ></button>

            <!-- <span v-if="added_extra_ids.includes(extra.id)">1 </span> -->

            <button
              v-if="!added_extra_ids.includes(extra.id)"
              class="icon plus"
            ></button>
            <h4>{{ extra.title }}</h4>
          </div>
          <div class="price">
            <span class="price">
              {{ formatPrice(extra.price) }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <button @click="done" :class="[completeItem ? '' : 'deactive', 'fill']">
      <div>
        {{ $t("customer_home.add_basket") }}
      </div>
      <div>
        {{
          total_price_item > 0
            ? formatPrice(total_price_item)
            : formatPrice(item.price - (item.price * item.discount) / 100)
        }}
      </div>
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    addRemoveExtra(extra) {
      this.added_extra_ids.includes(extra.id)
        ? this.removeExtraFromCart(extra)
        : this.addExtraToCart(extra);
    },
    addExtraToCart(extra) {
      if (!this.added_extra_ids.includes(extra.id)) {
        this.temp_item.extras.push(extra);
      }

      this.totalPriceItem();
    },
    removeExtraFromCart(extra) {
      if (this.temp_item.extras.length) {
        for (let j = 0; j <= this.temp_item.extras.length - 1; j++) {
          if (
            this.temp_item.extras[j] &&
            this.temp_item.extras[j].id == extra.id
          ) {
            this.temp_item.extras.splice(j, 1);
            this.totalPriceItem();

            return 0;
          }
        }
      }
      // this.totalPriceItem();
    },
    addCompulsoryToCart(compulsory, sub_compulsory) {
      //make a copy for save compulsory and sub compulsory
      if (this.checkAddedSubCompulsory(compulsory, sub_compulsory)) {
        if (compulsory.limit == 1) {
          let tmp_compulsory = Object.assign({}, compulsory);

          tmp_compulsory.sub_compulsories = [];

          tmp_compulsory.sub_compulsories[0] = sub_compulsory;

          //hold previous compulsories
          let pre_compulsories = [];

          this.temp_item.compulsories.forEach((element) => {
            pre_compulsories.push(element);
          });

          //push copy of compulsory to array of previous compulsories
          for (let index = 0; index < pre_compulsories.length; index++) {
            if (pre_compulsories[index].id == tmp_compulsory.id) {
              this.temp_item.compulsories[index] = tmp_compulsory;

              this.added_compulsory_ids();

              return;
            }
          }

          this.temp_item.compulsories.push(tmp_compulsory);

          this.added_compulsory_ids();
        } else {
          let tmp_compulsory = Object.assign({}, compulsory);

          if (this.lengthOfSubCompulsoryAdded(compulsory.id) > 0) {
            for (
              let index = 0;
              index < this.temp_item.compulsories.length;
              index++
            ) {
              if (this.temp_item.compulsories[index].id == compulsory.id) {
                if (
                  compulsory.limit ==
                  this.temp_item.compulsories[index].sub_compulsories.length
                ) {
                  return 0;
                } else {
                  this.temp_item.compulsories[index].sub_compulsories.push(
                    sub_compulsory
                  );
                }

             

                this.added_compulsory_ids();

                return 0;
              }
            }
          } else {
            tmp_compulsory.sub_compulsories = [];

            tmp_compulsory.sub_compulsories.push(sub_compulsory);
          }

          this.temp_item.compulsories.push(tmp_compulsory);

          this.added_compulsory_ids();
        }
      }
    },
    added_compulsory_ids() {
      this.added_sub_compulsories = [];
      this.temp_item.compulsories.forEach((element) => {
        element.sub_compulsories.forEach((sub_comp) => {
          this.added_sub_compulsories.push(sub_comp.id);
        });
      });

      this.totalPriceItem();

      return;
    },
    done() {
      if (
        this.item.compulsories.length == this.temp_item.compulsories.length &&
        this.completeItem
      ) {
        if (this.temp_item.extras.length) {
          this.temp_extras = this.temp_item.extras.slice();
        }

        this.temp_compulsories = this.temp_item.compulsories.slice();

        this.temp_item = Object.assign({}, this.item);

        this.$store.dispatch("addItemToCart", {
          item: this.temp_item,
          quantity: 1,
        });

        this.$store.dispatch("addCompulsoryToCart", {
          item: this.temp_item,
          index: this.$store.state.cart.length - 1,
          compulsories: this.temp_compulsories,
        });

        this.$store.dispatch("addExtraToCart", {
          item: this.temp_item,
          index: this.$store.state.cart.length - 1,
          extras: this.temp_extras,
        });

        this.closeClearModal();
      }
    },
    totalPriceItem() {
      let total = 0;
      this.temp_item.extras.forEach((element) => {
        total += element.price;
      });
      this.temp_item.compulsories.forEach((element) => {
        element.sub_compulsories.forEach((sub_comp) => {
          total += sub_comp.price;
        });
      });
      total += this.item.price - (this.item.price * this.item.discount) / 100;

      this.total_price_item = total;

      return;
    },
    checkAddedSubCompulsory(compulsory, sub_compulsory) {
      let found = true;

      this.temp_item.compulsories.forEach((element) => {
        if (compulsory.id == element.id) {
          element.sub_compulsories.forEach((iter) => {
            if (iter.id == sub_compulsory.id) {
              found = false;
            }
          });
        }
      });

      if (found == false) {
        this.removeSubCompulsory(compulsory, sub_compulsory);
      }

      return found;
    },
    removeSubCompulsory(compulsory, sub_compulsory) {
      if (compulsory.limit > 1) {
        for (
          let indexx = 0;
          indexx < this.temp_item.compulsories.length;
          indexx++
        ) {
          if (this.temp_item.compulsories[indexx].sub_compulsories.length > 1) {
            if (compulsory.id == this.temp_item.compulsories[indexx].id) {
              for (
                let index = 0;
                index <
                this.temp_item.compulsories[indexx].sub_compulsories.length;
                index++
              ) {
                if (
                  this.temp_item.compulsories[indexx].sub_compulsories[index]
                    .id == sub_compulsory.id
                ) {
                  this.temp_item.compulsories[indexx].sub_compulsories.splice(
                    index,
                    1
                  );
                }
              }
            }
          }
        }
        this.added_compulsory_ids();
      }
    },
    lengthOfSubCompulsoryAdded(compulsory_id) {
      let length_sub_added = 0;

      this.temp_item.compulsories.forEach((compulsory) => {
        if (compulsory.id == compulsory_id) {
          length_sub_added = compulsory.sub_compulsories.length;
        }
      });
      return length_sub_added;
    },
    compulsoryIsFull(compulsory) {
      return compulsory.limit == this.lengthOfSubCompulsoryAdded(compulsory.id);
    },
    closeClearModal() {
      this.temp_compulsories = [];
      this.temp_extras = [];

      this.temp_item = {
        compulsories: [],
        extras: [],
      };

      this.added_sub_compulsories = [];

      this.total_price_item = 0;

      this.closeAllCompulsory(this.item.compulsories.length);
      this.closeModal("extras_modal");

      this.$router.replace({ query: null }).catch(() => {});
    },
  },
  watch: {
    $route(to, from) {
      document.getElementById("scroll_").scrollTo(0, 0);

      this.temp_compulsories = [];
      this.temp_extras = [];

      this.temp_item = {
        compulsories: [],
        extras: [],
      };

      this.added_sub_compulsories = [];

      this.total_price_item = 0;
    },
  },
  computed: {
    added_extra_ids() {
      let ids = [];

      this.temp_item.extras.forEach((element) => {
        ids.push(element.id);
      });

      return ids;
    },
    completeItem() {
      if (this.item.compulsories && this.temp_item.compulsories) {
        return (
          this.temp_item.compulsories.length == this.item.compulsories.length &&
          this.compulsoryLimits
        );
      }
    },
    compulsoryLimits() {
      let limit = true;

      this.temp_item.compulsories.forEach((element) => {
        if (element.limit != element.sub_compulsories.length) {
          limit = false;
        }
      });

      return limit;
    },
  },
  props: {
    item: {},
    index: 0,
  },
  data() {
    return {
      added_sub_compulsories: [],
      temp_item: {
        compulsories: [],
        extras: [],
      },
      temp_compulsories: [],
      temp_extras: [],
      total_price_item: 0,
    };
  },
};
</script>
<style scoped>
.modal {
  gap: 12px;
  min-height: 90vh;
  background-color: #ffffff;
  height: fit-content;
  max-height: 90vh;
  overflow-y: hidden;
  justify-content: flex-start;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  max-width: 768px;
  margin: 0 auto;
  /* padding: 0; */
  padding: 0 0 85px;
}

.banner {
  width: 100%;
  min-height: 136px;
  border-radius: 8px 0 0;
  background-size: cover;
  background-position: center;
  background-image: url("../../assets/customer/menu/item.png");
  display: flex;
  padding: 12px;
  justify-content: flex-start;
  align-items: flex-start;
}

.extras {
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  max-height: 70vh;
  /* max-height: calc(90vh - 77px); */
  position: relative;
}
.extra {
  display: flex;
  width: 100%;
  justify-content: space-between;
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid #f5f6f7;
}
.fixed {
  padding: 0;
}
.scroll {
  overflow-y: scroll;
  padding: 0 12px;
}
.head {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  padding: 12px 12px 8px;
}

.extra-title,
.compulsory-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.extra-title {
  padding-top: 12px;
}
.optional,
.required,
.discount {
  font-weight: 500;
  font-size: 12px;
  color: #ff6a4d;
  background-color: #ffeeeb;
  border-radius: 8px;
  padding: 4px 8px;
}
.title {
  display: flex;
  gap: 8px;
  width: 68%;
  align-items: center;
}

span.price {
  color: #776282;
  font-size: 14px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.ingredients {
  font-weight: 300;
  font-size: 14px;
  color: #b3b3b3;
  margin: 0 12px;
  padding: 0 0 12px;
  border-bottom: 1px solid #f0edf2;
}

.deactive {
  opacity: 0.5;
}
.control {
  display: flex;
  width: auto;
  justify-content: flex-start;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  color: #776282;
}
div.price {
  display: flex;
  justify-content: flex-end;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #4d4d4d;
}
.icon {
  width: 24px;
  height: 24px;
  background-size: 20px;
  border-radius: 8px;
}

.plus {
  background-size: 24px;
  background-image: url("../../assets/common/unchecked.svg");
}

.minus {
  background-size: 24px;
  background-image: url("../../assets/common/checked_orange.svg");
}
.vegan {
  background-color: transparent;
  background-image: url("../../assets/customer/menu/vegan.svg");
}
.gluten {
  background-color: transparent;
  background-image: url("../../assets/customer/menu/gluten.svg");
}
.lactose {
  background-color: transparent;
  background-image: url("../../assets/customer/menu/lactose.svg");
}

/* .tip {
  background-color: transparent;
  background-size: 28px;
} */

h4 {
  display: flex;
  align-items: center;
  font-weight: 500;
}

.compulsory {
  padding: 14px 0 12px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  border-bottom: 1px solid #f0edf2;
}
.compulsory span {
  color: #b3b3b3;
  font-size: 12px;
}
.sub-compulsories {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
  height: 136px;
  overflow-y: hidden;
}
.show {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
}

.text {
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  text-align: center;
  color: #ff6a4d;
}

.arrow {
  background-color: transparent;
  background-image: url("../../assets/customer/menu/down.svg");
}

.sub-compulsory {
  width: 100%;
  border: 1px solid #8f93bf3d;
  height: 40px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #776282;
  cursor: pointer;
}

input[type="radio"] {
  appearance: none;
}

.active_sub_compulsory {
  border: 1px solid #ff6a4d;
  color: #ff6a4d;
}

.extra h4 {
  font-weight: 400;
  width: auto;
  font-size: 14px;
  line-height: 16px;
  color: #776282;
}

.head h4 {
  color: #4d4d4d;
  width: 60%;
  overflow-x: hidden;
  text-overflow: ellipsis;
  /* white-space: nowrap; */
  text-align: left;
}

button.fill {
  min-height: 53px;
  margin: 0 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
}

button.deactive {
  opacity: 0.4;
}

.change-price {
  background-color: #ffeeeb;
  border-radius: 8px;
  padding: 4px 8px;
  font-weight: 400;
  font-size: 14px;
  color: #ff6a4d;
}

s {
  font-weight: 400;
  font-size: 10px;
  color: #b3b3b3;
}

.close {
  background-image: url("../../assets/common/close.svg");
  height: 32px;
  width: 32px;
  background-color: #fafafa;
  background-size: 24px;
}

.off_compulsory {
  opacity: 0.3;
}
</style>