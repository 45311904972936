<template>
  <router-link :to="'/order/' + order.id">
    <div class="btn">
      <div class="order">
        <div
          class="photo"
          :style="[
            order.photo
              ? {
                  'background-image': 'url(' + order.photo + ')',
                }
              : '',
          ]"
        ></div>
        <div class="detail">
          <div class="head">
            <h3>{{ order.restaurant ? order.restaurant.title : "" }}</h3>
            <div v-if="$route.name == 'History'" class="icon arrow"></div>
          </div>
          <div class="time">
            <span>
              {{ formatDatetime(order.created_at) }}
            </span>
            <span>
              {{ "#" + order.id }}
            </span>
          </div>
          <div class="footer">
            <div v-if="order.status == 'rejected'" class="rejected">
              {{ $t("order.rejected") }}
            </div>
            <div v-else class="type">
              {{
                order.order_type == "at_restaurant"
                  ? $t("cart.have_at_restaurant")
                  : order.order_type == "pick_up"
                  ? $t("cart.pick_up")
                  : order.order_type == "delivery"
                  ? $t("order.deliver_to") + order.address.title
                  : ""
              }}
            </div>
            <div class="price">
              {{
                formatPrice(
                  parseFloat(order.total) +
                    parseFloat(order.delivery_charge) +
                    parseFloat(order.night_shift_charge) +
                    parseFloat(order.minimum_order_charge)
                )
              }}
            </div>
          </div>
        </div>
      </div>
      <button
        v-if="order.status == 'done' || order.status == 'rejected'"
        class="outline"
        @click.prevent="createNewBasket()"
      >
        {{ $t("order.repeat_order") }}
      </button>
    </div>
  </router-link>
</template>
<script>
export default {
  props: {
    order: {},
  },
  methods: {
    createNewBasket() {
      localStorage.menu = this.order.menu;
      localStorage.qr = this.order.menu;
      localStorage.restaurant_name = this.order.restaurant.title;
      this.$store.dispatch("createNewCart", {
        basket: this.order.json_items,
      });
      this.$router.push("/basket");
    },
  },
};
</script>
<style scoped>
.btn {
  flex-direction: column;
  padding: 12px;
  gap: 8px;
}
.order {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  gap: 12px;
}
.outline {
  height: 40px;
  font-weight: 400;
  font-size: 14px;
  color: #ff6a4d;
  width: 100%;
  background-color: #fff7f5;
  border: 0.4px solid #ffaa99;
  border-radius: 8px;
}

h3 {
  color: #4d4d4d;
  width: 65%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.detail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 64%;
  width: 70%;
}
.head {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.time {
  color: #b3b3b3;
  font-size: 14px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}
.arrow {
  background-image: url("../../assets/common/right_fill.svg");
}
.price {
  color: #776282;
  font-size: 14px;
}
.type {
  font-weight: 300;
  font-size: 14px;
  color: #776282;
}

.rejected {
  font-weight: 300;
  font-size: 14px;
  color: #ff6a4d;
}
.photo {
  min-width: 86px;
  width: 30%;
  height: 82px;
  border-radius: 8px;
  background-image: url("../../assets/customer/restaurant/restaurant.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
