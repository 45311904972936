<template>
  <div class="history">
    <div class="header">
      <div class="container">
        <div class="title">{{ $t("navbar.reservation") }}</div>
      </div>
    </div>
    <div class="content">
      <!-- search box codes -->
      <div class="search-container">
        <div class="search-btn">
          <select v-model="city" @change="fetch" name="city" id="city">
            <option
              v-for="the_city in cities"
              :key="the_city.id"
              :value="the_city"
            >
              {{ the_city.title }}
            </option>
          </select>
          <div class="icon location"></div>
        </div>
        <div class="search">
          <div class="icon"></div>
          <input
            v-model="keyword"
            @input="fetch"
            type="text"
            name="search"
            id="search-box"
            :placeholder="$t('customer_home.search_restaurant')"
          />
        </div>
      </div>

      <div
        v-for="(reservation, index) in reservations"
        :key="index"
        class="reservation"
        :id="'reservation' + index"
      >
        <router-link :to="'/menu/' + reservation.menu" class="btn restaurant">
          <div
            class="photo"
            :style="[
              reservation.photo
                ? {
                    'background-image': 'url(' + reservation.photo + ')',
                  }
                : '',
            ]"
          ></div>
          <div class="detail">
            <div class="head">
              <h3>
                {{ reservation.restaurant }}
              </h3>
            </div>
            <div class="footer">
              <div class="type">{{ reservation.type }}</div>
              <div class="rate">
                <div class="icon star"></div>
                <span>
                  {{ reservation.average + " / 5" }}
                </span>
              </div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
import EmptyReserves from "../components/customer/EmptyReserves.vue";
export default {
  mounted() {
    this.city = JSON.parse(localStorage.city);
    this.fetch();
  },
  components: { EmptyReserves },
  methods: {
    fetch() {
      if (localStorage.city) {
        this.global_loading.show = true;
        this.$http
          .get(
            this.$hostname +
              "/reservation-search?city=" +
              this.city.id +
              "&keyword=" +
              this.keyword
          )
          .then((resp) => {
            this.successRequest();
            console.log(resp.data.restaurants);
            this.reservations = resp.data.restaurants;
          })
          .catch((err) => {
            this.showErrors(err);
          });
      }
    },
  },
  data() {
    return {
      reservations: [],
      city: {},
      keyword: "",
    };
  },
};
</script>
<style scoped>
.content {
  padding: 70px 16px 110px;
  height: 88vh;
  gap: 20px;
}

.reservation {
  transition: 0.3s ease;
  height: 200px;
  min-height: 200px;
  overflow: hidden;
  background-color: #f5f6f7;
  border-radius: 8px;
  gap: 12px;
  background-color: #fff7f5;
  border-radius: 0;
  padding: 0;
  padding-bottom: 12px;
  position: relative;
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.restaurant {
  justify-content: flex-start;
  gap: 12px;
  padding: 0;
  flex-direction: column;
  cursor: pointer;
  background-color: transparent;
}

h3 {
  color: #4d4d4d;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
  font-weight: 500;
  color: #4d4d4d;
  font-size: 18px;
}

.detail {
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 0 10px;
}

.head {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.type {
  color: #b3b3b3;
  font-size: 14px;
}

.footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.photo {
  min-width: 86px;
  width: 100%;
  height: 130px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background-image: url("../assets/customer/restaurant/restaurant.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.down {
  background-position: center;
  background-repeat: no-repeat;
  background-size: 24px;
  width: 24px;
  transition: 0.3s ease;
  background-image: url("../assets/common/down.svg");
}

.search {
  background-color: #f5f6f7;
  border-radius: 8px;
  display: flex;
  align-items: center;
  height: 40px;
  margin-bottom: 8px;
  max-width: 768px;
  width: calc(100% - 120px);
}

.location {
  width: 20px;
  position: absolute;
  top: 6px;
  left: 4px;
  background-image: url("../assets/customer/restaurant/location.svg");
}
.search-btn {
  position: relative;
  width: 120px;
}

select {
  width: 100%;
  cursor: pointer;
  height: 40px;
  text-align: left;
  padding: 10px;
  padding-left: 25px;
  background-color: #f5f6f7;
  border-radius: 8px;
  margin-bottom: 12px;
  color: #4d4d4d;
  font-weight: 400;
  font-size: 14px;
}

.search-container {
  display: flex;
  gap: 6px;
}
</style>
