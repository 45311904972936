<template>
  <div id="city_modal" class="modal">
    <div class="question">
      <div class="title">
        {{ $t("customer_home.nearest_rest") }}
      </div>
      <div class="location-filter">
        <button @click="whereAreYou()" class="icon location"></button>

        <div class="slidecontainer">
          <v-app>
            <v-slider
              v-model="radius"
              :color="'#FF6A4D'"
              track-color="#ffeeeb"
              thumb-label="always"
              @change="setLocation()"
              :max="15"
              :min="1"
              step="1"
              show-ticks="always"
              tick-size="10"
            >
              <template>
                {{ radius }}
              </template>
            </v-slider>
          </v-app>
        </div>

        <span>Radius (km)</span>
      </div>
      <div class="seprater">
        <hr />

        <span> {{ $t("registration.or") }} </span>
        <hr />
      </div>
      <div class="cities">
        <select
          class="option"
          v-model="selected_city"
          @change="setCity(selected_city)"
          name="city"
          id="city"
        >
          <option v-for="(city, index) in cities" :key="index" :value="city">
            {{ city.title }}
          </option>
        </select>
        <!-- <div
          v-for="(city, index) in cities"
          :key="index"
          :class="[selected_city == city ? 'checked' : '', 'option']"
          @click="setCity(city)"
        >
          <label :for="city.title">{{ city.title }}</label>
          <input
            type="radio"
            name="city"
            :id="city.title"
            :checked="selected_city == city"
          />
        </div> -->
      </div>
    </div>
    <button @click="done" class="fill">{{ $t("customer_home.done") }}</button>
  </div>
</template>
<script>
export default {
  mounted() {
    if (localStorage.city) {
      this.selected_city = JSON.parse(localStorage.city);
    } else {
      this.setLocation();
    }
  },

  data() {
    return {
      selected_city: {},
      min: 1,
      max: 20,
      radius: 1,
      geolocation: {
        latitude: "",
        longitude: "",
        radius: 0,
      },
    };
  },
  computed: {
    radiusKm() {
      return this.radius + " km";
    },
  },
  methods: {
    setCity(city) {
      this.selected_city = city;
      if (!this.selected_city.id) {
        this.selected_city = this.cities[0];
      }
      localStorage.city = JSON.stringify(this.selected_city);
      localStorage.removeItem("latitude");
      localStorage.removeItem("longitude");
      this.radius = 1;

      this.$emit("selected_city", this.selected_city);
    },
    done() {
      this.closeModal("city_modal");
    },
    setLocation() {
      this.whereAreYou();
      localStorage.removeItem("city");

      this.selected_city = {};

      setTimeout(() => {
        this.$emit("radius", this.radius);
      }, 100);
    },
  },
};
</script>
<style scoped>
.modal {
  height: 436px;
  gap: 12px;
  justify-content: flex-start;
}
.question {
  height: 252px;
  background-color: #ffffff;
  border-radius: 16px;
  padding: 8px;
}
.cities {
  height: 48px;
  background: #fff7f5;
  border-radius: 16px;
}
.option {
  width: 100%;
  text-align: center;
  padding: 16px;
  font-weight: normal;
  font-size: 18px;
  color: #b3b3b3;
}

.checked {
  font-weight: 500;
  font-size: 18px;
  color: #4d4d4d;
  border-top: 1px solid #f5f6f7;
  border-bottom: 1px solid #f5f6f7;
}

input[type="radio"] {
  appearance: none;
}

.location-filter {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 16px;
  background: #fff7f5;
  border-radius: 16px;
}
.location {
  width: 40px;
  height: 40px;
  background-color: #ffeeeb;
  border-radius: 8px;
  background-image: url("../../assets/customer/restaurant/gps.svg");
}
.slidecontainer {
  width: calc(100% - 122px);
  height: 32px;
}

.location-filter span {
  font-size: 12px;
  text-align: center;
  color: #b3b3b3;
}

.title {
  font-size: 16px;
  text-align: center;
  color: #737373;
  padding: 16px;
}
.seprater {
  font-weight: 400;
  font-size: 16px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #cccccc;
}
.seprater span {
  padding: 10px;
}
hr {
  border: 1px solid #f3f4f5;
  width: 50%;
}
</style>
