<template>
  <div id="call_modal" class="modal">
    <div class="question">
      <h2>{{ $t("call.enter_your_table") }}</h2>
      <div class="desc">
        {{ $t("call.only_restaurant") }}
      </div>
      <div class="table">
        <h4>
          <label for="table"> {{ $t("call.table_number") }} </label>
        </h4>
        <input v-model="table" type="text" name="table" id="table" />
      </div>
    </div>
    <button @click="done" class="fill">{{ $t("call.call") }}</button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      table: "",
    };
  },
  methods: {
    done() {
      let data = {
        table: this.table,
        qr: this.$store.state.qr,
        type: "help",
      };
      this.global_loading.show = true;
      this.$http
        .post(this.$hostname + "/call-waiter", data)
        .then((resp) => {
          this.successRequest();
          this.closeModal("call_modal");
          this.global_success.message = this.$t("call.coming");
          setTimeout(() => {
            this.showModal("succeed_modal");
          }, 301);

          setTimeout(() => {
            this.closeModal("succeed_modal");
          }, 3000);
        })
        .catch((err) => {
          this.showErrors(err);
        });
    },
  },
};
</script>
<style scoped>
.modal {
  height: 320px;
  gap: 12px;
  justify-content: flex-start;
}
.question {
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
}
h2 {
  font-size: 18px;
  text-align: center;
  color: #737373;
  padding: 16px 0 8px;
  font-weight: normal;
}
.desc {
  font-size: 14px;
  text-align: center;
  color: #ff6a4d;
  /* color: #b3b3b3; */
  padding: 0 0 12px;
}
.table {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  border-top: 1px solid #f5f6f7;
}
button {
  border-radius: 16px;
}
input[type="text"] {
  background-color: #f0edf2;
  border-radius: 8px;
  height: 32px;
  width: 80px;
  text-align: center;
}
</style>