<template>
  <div>
    <button @click="showModal('call_modal')" class="icon call"></button>
  </div>
</template>
<script>
export default {};
</script>
<style scoped>
.call {
  background-image: url("../../assets/customer/menu/call.svg");
}

.icon {
  width: 40px;
  height: 40px;
  background-size: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #f5f6f7;
  border-radius: 8px;
}
</style>