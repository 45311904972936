<template>
  <div class="scan">
    <div class="background">
      <QRCodeReader />
    </div>
    <QRCodeReader />
  </div>
</template>

<script>
import QRCodeReader from "../components/customer/QRCodeReader.vue";

export default {
  components: {
    QRCodeReader,
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
.scan {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #909699;
  height: 100vh;
}

.scan .camera {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 240px;
  width: 240px;
  border-radius: 10px;
  margin: auto;
  position: absolute;
  top: 25%;
}
.scan .camera .qrcode-stream-camera {
  border-radius: 20px;
}
.background .camera {
  position: absolute;
  top: 0%;
  left: 0%;
  filter: blur(10px);
  height: 100vh !important;
  width: 100% !important;
}
</style>