<template>
  <div id="drinks_modal" class="modal">
    <div class="drinks">
      <div
        v-for="(drink, index) in customer_drinks.data"
        :key="index"
        class="drink"
        v-if="drink.status == 1"
      >
        <h4>{{ drink.title }}</h4>
        <div class="price">
          <span class="price">
            {{ formatPrice(drink.price) }}
          </span>
          <div class="control">
            <button
              v-if="added_drink_ids.includes(drink.id)"
              @click.prevent="addDrinkToCart(drink)"
              class="icon minus"
            ></button>

            <span v-if="added_drink_ids.includes(drink.id)">1 </span>

            <button
              :class="[
                added_drink_ids.includes(drink.id) ? 'deactive' : '',
                'icon',
                'plus',
              ]"
              @click.prevent="addDrinkToCart(drink)"
            ></button>
          </div>
        </div>
      </div>
    </div>
    <button @click="closeModal('drinks_modal')" class="fill">
      {{ $t("customer_home.done") }}
    </button>
  </div>
</template>
<script>
export default {
  methods: {
    addDrinkToCart(drink) {
      this.$store.dispatch("addDrinkToCart", {
        item: this.item,
        index: this.index,
        drink: drink,
      });
    },
  },
  computed: {
    added_drink_ids() {
      let ids = [];
      for (let i = 0; i < this.$store.state.cart.length; i++) {
        if (i == this.index)
          this.$store.state.cart[i].drinks.forEach((element) => {
            ids.push(element.id);
          });
      }

      return ids;
    },
  },
  props: {
    item: {},
    index: 0,
  },
};
</script>
<style scoped>
.modal {
  height: fit-content;
  min-height: 300px;
  gap: 12px;
  justify-content: flex-start;
}
.drinks {
  background-color: #ffffff;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  padding: 0 12px;
  max-height: 500px;
  overflow-y: scroll;
}

.drink {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 18px 0;
  border-bottom: 1px solid #f5f6f7;
}

span.price {
  color: #776282;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button.accept {
  border-radius: 16px;
  color: #ff6a4d;
  font-size: 18px;
  background-color: #ffffff;
  height: 53px;
}
.deactive {
  opacity: 0.5;
}
.control {
  display: flex;
  width: fit-content;
  justify-content: flex-end;
  gap: 10px;
  align-items: center;
  font-size: 14px;
  color: #776282;
}
div.price {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  align-items: center;
}
.icon {
  width: 24px;
  height: 24px;
  background-size: 20px;
  background-color: #ffeeeb;
  border-radius: 8px;
}

.plus {
  background-image: url("../../assets/customer/menu/plus.svg");
}

.minus {
  background-image: url("../../assets/customer/menu/minus.svg");
}
</style>