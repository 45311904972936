<template>
  <div class="basket">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/menu/' + pre_route"></back-button>
        <div class="title">{{ $t("cart.order_review") }}</div>
        <div class="actions">
          <call-waiter></call-waiter>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="title">{{ restaurantName }}</div>
      <div v-if="can_not_order" class="warning">
        <div class="icon warn"></div>
        <div class="desc">
          {{ $t("order.readonly_message") }}
        </div>
      </div>
      <div class="items">
        <div class="item btn" v-for="(item, index) in cart" :key="index">
          <div class="detail">
            <div
              class="photo"
              :style="[
                item.item.photo
                  ? {
                      'background-image': 'url(' + item.item.photo + ')',
                    }
                  : '',
              ]"
            ></div>
            <div class="info">
              <div class="head">
                <h3>
                  {{ item.item.title }}
                </h3>
                <span
                  v-if="
                    item.item.vegan == 1 ||
                    item.item.gluten == 1 ||
                    item.item.lactose == 1
                  "
                  >{{
                    (item.item.vegan == 1 ? " (V) " : "") +
                    (item.item.gluten == 1 ? " (G) " : "") +
                    (item.item.lactose == 1 ? " (L) " : "")
                  }}</span
                >
              </div>
              <div
                class="ingredients"
                v-html="
                  $i18n.locale == 'en'
                    ? item.item.description_en
                    : item.item.description_fi
                "
              ></div>
              <div class="price">
                <span v-if="item.item.discount > 0" class="price"
                  ><s>{{ formatPrice(item.item.price) }}</s>
                  &nbsp;
                  {{
                    formatPrice(
                      item.item.price -
                        (item.item.price * item.item.discount) / 100
                    )
                  }}</span
                >
                <span v-else class="price">{{
                  formatPrice(item.item.price)
                }}</span>
                <add-item :item="item.item" :index_of_item="index"></add-item>
              </div>
            </div>
          </div>
          <div class="adder">
            <button @click="addDrink(item.item, index)">
              {{ $t("cart.add_drink") }}
            </button>
          </div>
          <div class="extras">
            <div
              class="drink"
              v-for="(drink, index_dr) in item.drinks"
              :key="index_dr"
            >
              <div>
                {{ drink.title }}
              </div>
              <div class="detail">
                <span class="quantity"> x1 </span>

                <span class="price">
                  {{ formatPrice(drink.price) }}
                </span>
              </div>
            </div>
            <div
              class="extras"
              v-for="(compulsory, index_co) in item.compulsories"
              :key="index_co"
            >
              <h4>{{ compulsory.title + ":" }}</h4>
              <div
                v-for="sub_compulsory in compulsory.sub_compulsories"
                :key="sub_compulsory.id"
                class="extra"
              >
                <div>
                  {{ "- " + sub_compulsory.title }}
                </div>
                <div class="detail">
                  <span class="quantity"> x1 </span>
                  <span class="price">
                    {{ formatPrice(sub_compulsory.price) }}
                  </span>
                </div>
              </div>
            </div>
            <div
              class="extra"
              v-for="(extra, index_ex) in item.extras"
              :key="index_ex"
            >
              <div>
                {{ extra.title }}
              </div>
              <div class="detail">
                <span class="quantity"> x1 </span>
                <span class="price">
                  {{ formatPrice(extra.price) }}
                </span>
              </div>
            </div>
          </div>
          <div class="total">
            <h4>
              {{ $t("cart.total_price") + " " + $t("cart.incl_vat") }}
            </h4>

            <div class="price">
              {{ formatPrice(totalPriceItem(item)) }}
            </div>
          </div>
        </div>
      </div>
      <div class="comment">
        <h4>
          {{ $t("cart.comment") }}
        </h4>
        <textarea
          name="comment"
          :placeholder="$t('order.type_here')"
          id="comment"
          cols="30"
          rows="10"
          v-model="comment"
          @change="setComment"
        ></textarea>
      </div>
    </div>
    <continue></continue>
    <div id="blur_background" class="blur-bg">
      <call-waiter-modal></call-waiter-modal>
      <succeed-modal :message="global_success.message"></succeed-modal>
      <extras-modal
        :index="$store.state.cart.length - 1"
        :item="selecting_item.data"
      ></extras-modal>

      <drinks-modal :index="index_of_item" :item="selected_item"></drinks-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../components/common/BackButton.vue";
import Continue from "../components/customer/Continue.vue";
import AddItem from "../components/customer/AddItem.vue";
import DrinksModal from "../components/customer/DrinksModal.vue";
import CallWaiter from "../components/customer/CallWaiter.vue";
import CallWaiterModal from "../components/customer/CallWaiterModal.vue";
import SucceedModal from "../components/customer/SucceedModal.vue";
import ExtrasModal from "../components/customer/ExtrasModal.vue";

export default {
  mounted() {
   
    this.comment = localStorage.comment;
    this.pre_route = localStorage.menu;

    localStorage.removeItem("night_shift_charge");

    this.can_not_order =
      localStorage.pick_up == 0 &&
      localStorage.delivery == 0 &&
      localStorage.make_order == 0;

    this.$store.dispatch("addDeliveryCharge", {
      delivery_charge: this.delivery_charge,
    });
  },
  components: {
    BackButton,
    Continue,
    AddItem,
    DrinksModal,
    CallWaiter,
    SucceedModal,
    CallWaiterModal,
    ExtrasModal,
  },
  data() {
    return {
      selected_item: {},
      index_of_item: 0,
      comment: "",
      pre_route: "",
      delivery_charge: 0,
      can_not_order: false,
    };
  },
  methods: {
   
    addExtra(item, index) {
      this.fetchExtras(item);
      this.selected_item = item;
      this.index_of_item = index;
      this.showModal("extras_modal");
    },
    addDrink(item, index) {
      this.fetchDrinks();
      this.selected_item = item;
      this.index_of_item = index;
      this.showModal("drinks_modal");
    },
    setComment() {
      localStorage.comment = this.comment;
    },
    totalPriceItem(item) {
      let total = 0;

      total += item.item.price - (item.item.price * item.item.discount) / 100;

      if (item.extras.length) {
        item.extras.forEach((extra) => {
          total += extra.price;
        });
      }

      if (item.drinks.length) {
        item.drinks.forEach((drink) => {
          total += drink.price;
        });
      }

      if (item.compulsories.length) {
        item.compulsories.forEach((compulsory) => {
          compulsory.sub_compulsories.forEach((sub_compulsory) => {
            total += sub_compulsory.price;
          });
        });
      }

      return total;
    },
  },
  computed: {
    cart() {
      if (!this.$route.query.basket_id && this.$store.state.cart.length == 0) {
        this.$router.push("/menu/" + localStorage.qr);
      }

      return this.$store.state.cart;
    },
    total() {
      return this.$store.state.total;
    },
    restaurantName() {
      return localStorage.restaurant_name;
    },
  },
};
</script>
<style scoped>
.menu {
  overflow: hidden;
  max-height: 100vh;
}

.container {
  justify-content: space-between;
}

.content .title {
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: #b3b3b3;
  /* padding: 16px 0; */
}
.content {
  padding: 90px 16px 128px;
  gap: 12px;
  overflow-y: scroll;
}

.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.items {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  gap: 12px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0edf2;
}

.photo {
  min-width: 86px;
  width: 30%;
  height: 82px;
  border-radius: 8px;
  background-image: url("../assets/customer/menu/item.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.btn {
  flex-direction: column;
  justify-content: flex-start;
  gap: 12px;
  padding: 12px;
}
h3 {
  font-size: 18px;
  line-height: 21px;
  color: #4d4d4d;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: left;
}
.detail {
  display: flex;
  gap: 12px;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
}
.detail .head span {
  font-weight: 300;
  font-size: 12px;
  color: #b3b3b3;
}
.head {
  display: flex;
  justify-content: flex-start;
  gap: 4px;
  align-items: center;
  width: 100%;
}
.info {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 66%;
  height: 100%;
  justify-content: space-between;
}

.ingredients {
  font-weight: 300;
  font-size: 14px;
  color: #b3b3b3;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 16px;
}

.price {
  color: #776282;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.quantity {
  font-weight: 300;
  font-size: 14px;
  color: #737373;
}

.adder {
  width: 100%;
  display: flex;
  gap: 8px;
}

.extras {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.extras .extra {
  justify-content: flex-end;
}

.extras .extra div {
  width: 49%;
}

.adder button {
  width: 100%;
  border: 1px solid #ff6a4d;
  border-radius: 8px;
  height: 34px;
  background-color: #ffffff;
  color: #ff6a4d;
  font-size: 14px;
}

.drink,
.extra {
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: 300;
  font-size: 14px;
  color: #737373;
}
.drink div,
.extra div {
  width: 50%;
}
.total h4 {
  color: #737373;
}

.total {
  display: flex;
  width: 100%;
  padding: 10px 0;
  justify-content: space-between;
  border-top: 1px solid #f0edf2;
}
.total .price {
  font-size: 16px;
}
.comment h4 {
  color: #b3b3b3;
  padding: 0 0 8px;
}

.comment textarea {
  background-color: #f5f6f7;
  border-radius: 8px;
  padding: 12px;
  outline: none;
  resize: none;
  height: 72px;
  width: 100%;
}
.comment textarea::placeholder {
  color: #b3b3b3;
}

.warning {
  display: flex;
  align-items: center;
  background: #fff7f5;
  border-radius: 8px;
  padding: 12px 8px;
  color: #ff6a4d;
  gap: 10px;
}
.warning .desc {
  font-weight: 400;
  font-size: 14px;
}
.warn {
  width: 46px;
  height: 20px;
  background-size: 20px;
  background-image: url("../assets/customer/menu/warn.svg");
}
</style>