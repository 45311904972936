var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"navbar",attrs:{"id":"navigation"}},[_c('div',{staticClass:"container"},[_c('router-link',{attrs:{"to":"/"}},[_c('div',{staticClass:"btn"},[_c('div',{class:[
            _vm.$route.name == 'Home' ? 'active-home' : 'deactive-home',
            'icon' ]}),_c('div',{class:[
            _vm.$route.name == 'Home' ? 'active-text' : 'deactive-text',
            'caption' ]},[_vm._v(" "+_vm._s(_vm.$t("navbar.home"))+" ")])])]),_c('router-link',{attrs:{"to":"/reservation"}},[_c('div',{staticClass:"btn"},[_c('div',{class:[
            _vm.$route.name == 'Reservation'
              ? 'active-reservation'
              : 'deactive-reservation',
            'icon' ]}),_c('div',{class:[
            _vm.$route.name == 'Reservation' ? 'active-text' : 'deactive-text',
            'caption' ]},[_vm._v(" "+_vm._s(_vm.$t("navbar.reservation"))+" ")])])]),_c('router-link',{attrs:{"to":"/history"}},[_c('div',{staticClass:"btn"},[_c('div',{class:[
            _vm.history_routes.includes(_vm.$route.name)
              ? 'active-history'
              : 'deactive-history',
            'icon' ]},[(_vm.customer_orders.has_in_progress)?_c('span',{staticClass:"badge"},[_vm._v("•")]):_vm._e()]),_c('div',{class:[
            _vm.history_routes.includes(_vm.$route.name)
              ? 'active-text'
              : 'deactive-text',
            'caption' ]},[_vm._v(" "+_vm._s(_vm.$t("navbar.history"))+" ")])])]),_c('router-link',{attrs:{"to":"/account"}},[_c('div',{staticClass:"btn"},[_c('div',{class:[
            _vm.account_routes.includes(_vm.$route.name)
              ? 'active-account'
              : 'deactive-account',
            'icon' ]}),_c('div',{class:[
            _vm.account_routes.includes(_vm.$route.name)
              ? 'active-text'
              : 'deactive-text',
            'caption' ]},[_vm._v(" "+_vm._s(_vm.$t("navbar.account"))+" ")])])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }