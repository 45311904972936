import { render, staticRenderFns } from "./BasketBtn.vue?vue&type=template&id=251d8139&scoped=true&"
import script from "./BasketBtn.vue?vue&type=script&lang=js&"
export * from "./BasketBtn.vue?vue&type=script&lang=js&"
import style0 from "./BasketBtn.vue?vue&type=style&index=0&id=251d8139&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "251d8139",
  null
  
)

export default component.exports