<template>
  <div class="addresses">
    <div class="header">
      <div class="container">
        <back-button :back_to="'/account'"></back-button>
        <div class="title">{{ $t("account.my_address") }}</div>
        <h4 @click="editingMode()">
          {{ !editing_mode ? $t("profile.edit") : $t("customer_home.done") }}
        </h4>
      </div>
    </div>
    <div class="content">
      <div class="addresses-box">
        <div
          v-for="(address, index) in customer_addresses.data"
          :key="index"
          class="btn address"
        >
          <div class="head">
            <div class="active-text">{{ address.title }}</div>
            <div v-if="editing_mode" class="actions">
              <button
                @click="
                  showDeleteEditModal('delete_address_modal', address, false)
                "
                class="icon delete"
              ></button>
              <button
                @click="
                  showDeleteEditModal('edit_address_modal', address, true)
                "
                class="icon edit"
              ></button>
            </div>
          </div>
          <div class="description">
            {{
              address.description +
              (address.door_code ? ", " + address.door_code : "") +
              (address.zip_code ? ", " + address.zip_code : "") +
              (address.city ? " " + address.city : "")
            }}
          </div>
        </div>
      </div>

      <button
        @click="showDeleteEditModal('edit_address_modal', {}, false)"
        class="fill"
      >
        {{ "Add a new address" }}
      </button>
    </div>
    <div id="blur_background" class="blur-bg">
      <edit-address-modal
        :edit="edit"
        :address="selected_address"
      ></edit-address-modal>
      <delete-address-modal
        :title="selected_address.title"
        :id="selected_address.id"
      ></delete-address-modal>
    </div>
  </div>
</template>
<script>
import BackButton from "../../components/common/BackButton.vue";
import DeleteAddressModal from "../../components/customer/DeleteAddressModal.vue";
import EditAddressModal from "../../components/customer/EditAddressModal.vue";

export default {
  components: { BackButton, DeleteAddressModal, EditAddressModal },
  mounted() {
    this.fetchAddresses();
  },
  methods: {
    showDeleteEditModal(modal_id, address, edit) {
      this.$router.replace(`/addresses?address=true`).catch(() => {});

      if (address.orders_count > 0 && modal_id == "delete_address_modal") {
        this.global_error.message = this.$t("account.warn_address");

        this.global_error.show = true;
      } else {
        this.showModal(modal_id);
        this.edit = edit;

        this.selected_address = address;
      }
    },
    editingMode() {
      this.editing_mode = !this.editing_mode;
    },
  },
  data() {
    return {
      selected_address: {},
      edit: false,
      editing_mode: false,
    };
  },
};
</script>
<style scoped>
.container {
  justify-content: space-between;
}

.content {
  height: 100vh;
  justify-content: space-between;
  padding: 70px 16px;
  gap: 12px;
}

.btn {
  padding: 12px;
}

.addresses {
  max-height: 100%;
  overflow: hidden;
}

.addresses-box {
  display: flex;
  flex-direction: column;
  gap: 12px;
  overflow-y: scroll;
}

.fill {
  border-radius: 8px;
}

.container h4 {
  color: #ff6a4d;
  width: fit-content;
  cursor: pointer;
}

.active-text {
  font-weight: 400;
  font-size: 16px;
  color: #4d4d4d;
}

.edit {
  background-image: url("../../assets/customer/menu/pen.svg");
}

.delete {
  background-image: url("../../assets/customer/menu/trash.svg");
}
</style>